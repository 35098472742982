import Header from './HeaderSection';
import { Container, Box } from '@mui/material';
import FooterSection from './FooterSection';

const Layout = ({ children }) => {
  return (
    <Container maxWidth="lg" disableGutters sx={{ minHeight: '100vh', backgroundColor: 'secondaryBlue2.main', border: '0px dashed grey' }}>
      <Header />
      {children}
    </Container>
  );
};

export default Layout;
