import { createTheme, responsiveFontSizes } from '@mui/material';
import { red, blue, green, yellow } from '@mui/material/colors';

//'&:hover': { backgroundColor: 'custom.main'}

export const Colors = {
  primary: '#1972D6',
  secondary: '#d1adcc',
  success: '#4CAF50',
  info: '#00a2ff',
  danger: '#FF5722',
  warning: '#FFC107',
  dark: '#0e1b20',
  light: '#aaa',
  muted: '#abafb3',
  border: '#DDDFE1',
  inverse: '#2F3D4A',
  shaft: '#333',

  primaryBlue1: '#0A4A92',
  primaryBlue2: '#1972D6',
  secondaryBlue1: '#A3C7EF',
  secondaryBlue2: '#E8F1FB',

  purple:'#BC8DF7',
  lightBlue1: '#C6E1FF',

  ///////////////
  // Grays
  ///////////////
  dim_grey: '#696969',
  dove_gray: '#d5d5d5',
  body_bg: '#f3f6f9',
  light_gray: 'rgb(230,230,230)',
  ///////////////
  // Solid Color
  ///////////////
  white: '#fff',
  black: '#000',

  ////////////////////
  //////Gradients/////
  ////////////////////
  blueGradient1: `linear-gradient(132.98deg, #1972D6 0.38%, #7D47DC 116.34%)`,
};

let theme = createTheme({
  palette: {
    primary: {
      main: '#1972D6',
      contrastText: '#fff',
    },
    primaryBlue1: {
      main: '#0A4A92',
      contrastText: '#fff',
      dark: '#a6e22e',
    },
    primaryBlue2: {
      main: '#1972D6',
      contrastText: '#fff',
      dark: '#a6e22e',
    },
    secondaryBlue1: {
      main: '#A3C7EF',
      contrastText: '#0A4A92',
    },
    secondaryBlue2: {
      main: '#E8F1FB',
      contrastText: '#000',
    },

    purple: {
      main: '#BC8DF7',
      contrastText: '#fff',
    },

    custom: {
      light: green[500],
      main: blue[500],
      dark: red[500],
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
  },
  typography: {
    // h3: {
    //     fontSize:'4em',
    //     fontWeight: '700',
    //   },
    // h4: {
    //   fontSize: '32px',
    //   fontWeight: '400',
    //   lineHeight:'40px'
    // },
    //     body1: {
    //       fontWeight: 500,
    //     },
  },

  components: {
    // Name of the component
    MuiContainer: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          padding: 0,
          maxWidth: '1440px',
        },
      },
    },

    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          backgroundColor: Colors.secondaryBlue1,
          '&.Mui-selected': {
            backgroundColor: Colors.primaryBlue1,
            color: Colors.white,
          },
        },
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1488, // padding of 24, 24. 1440px is the width
    },
  },
});

// theme.typography.h3 = {
//   // h3: {
//   //   fontSize:'2em',
//   //   fontWeight: '700',
//   // },
//   // [theme.breakpoints.up('md')]: {
//   //   fontSize: '4em',
//   // },
// };

theme = responsiveFontSizes(theme);

export default theme;
