import { useState } from 'react';
import { Box, Typography, Button, Modal, Grid, Link, Alert } from '@mui/material';
import { Colors } from '../../styles/theme';
import { RegisterTextField, RegisterMultiLineTextField } from '../../styles/registerFormControls';
import { Controller, useForm } from 'react-hook-form';
import AlertDialog from './AlertDialog';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axiosInstance from '../../api/axios';
import ReactGA from 'react-ga';
import useAnalytics from '../../shared/useAnalytics';
import AuthService from '../../api/authService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function MessageUsModal(props) {
  const user = AuthService.getCurrentUser();
  const gaEvent = useAnalytics('MessageUsModal');
  const [showConfirm, setShowConfirm] = useState(false);

  const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('This is not a valid email.'),

    message: Yup.string()
      //.test('len', 'Message must be atleast 3 characters.', (val) => val && val.toString().length >= 3)
      .required('This field is required!'),
  });

  const defaultValues = {
    userName: user.userName,
    email: '',
    phone: '',
    message: '',
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onChange', defaultValues, resolver: yupResolver(validationSchema) });

  const closeModal = () => {
    setShowConfirm(false);
    props.handleClose();
    reset();
  };
  const onSubmit = async (values) => {
    var obj = values;

    const url = 'api/Case/AddSendMessage';
    try {
      var resp = await axiosInstance.post(url, obj);
      console.log(resp.data);
    } catch (error) {
      console.error(error.message);
    }

    setShowConfirm(true);
    setTimeout(() => closeModal(), 2000);
    //props.handleClose();
  };
  const onError = (errors, e) => console.log(errors);

  return (
    <Modal open={props.open} onClose={props.handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      {showConfirm ? (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
          }}
        >
          <Typography sx={{ py: 2 }}> Message Submitted</Typography>
          <Button variant="contained" color="primary" onClick={() => closeModal()} sx={{ textTransform: 'none', borderRadius: 100, px: 6 }}>
            Ok
          </Button>
        </Box>
      ) : (
        <Box sx={style}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography sx={{ fontWeight: 500, fontSize: '22px', lineHeight: '28px' }}>Send Message</Typography>
          </Box>

          <Box component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate sx={{ mt: 4, border: '0px dashed green' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RegisterTextField
                      id="email"
                      onChange={onChange}
                      value={value}
                      label="Email Address"
                      error={!!errors.email}
                      helperText={errors.email && `${errors.email.message}`}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RegisterTextField id="phone" onChange={onChange} value={value} label="Phone" error={!!errors.phone} helperText={errors.phone && `${errors.phone.message}`} />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="message"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RegisterMultiLineTextField
                      id="message"
                      rows={5}
                      onChange={onChange}
                      value={value}
                      label="Message"
                      error={!!errors.message}
                      helperText={errors.message && `${errors.message.message}`}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              <Button color="primary" variant="contained" type="submit" sx={{ textTransform: 'none', borderRadius: 100 }}>
                Send Message
              </Button>

              <Button variant="text" color="inherit" onClick={props.handleClose}>
                <Typography sx={{ color: Colors.black, textTransform: 'none', mx: 5 }}>Cancel</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Modal>
  );
}
