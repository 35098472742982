import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export const facilityValidationSchema = Yup.object().shape({
  hospitalName: Yup.string().required('Facility Name is required').min(3, 'Facility Name must be at least 3 characters'),
  //   facilityName1: Yup.string().required('Facility Name1 is required').min(3, 'Facility Name1 must be at least 3 characters'),
  //   username: Yup.string().required('Username is required').min(6, 'Username must be at least 6 characters').max(20, 'Username must not exceed 20 characters'),
  //   email: Yup.string().required('Email is required').email('Email is invalid'),
  //   password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters').max(40, 'Password must not exceed 40 characters'),
  //   confirmPassword: Yup.string()
  //     .required('Confirm Password is required')
  //     .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
  //   acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required'),
  // reorderType: Yup.string().label('Reorder Type').required(),
  // address1: Yup.string().required('Ship To Address1 is required'),
  // city: Yup.string().required('Ship To City is required'),
  // state: Yup.string().required('Ship To State is required'),
  // zip5: Yup.string().required('Ship To Zip is required'),
});

export const reorderValidationSchema = Yup.object().shape({
  address1: Yup.string().required('Ship To Address1 is required'),
  city: Yup.string().required('Ship To City is required'),
  state: Yup.string().required('Ship To State is required'),
  zip5: Yup.string().required('Ship To Zip is required'),
});

export const manufacturerValidationSchema = Yup.object().shape({
  manufacturerName: Yup.string().required('Manufacturer Name is required'),
  sortNumber: Yup.number().required('Sort Number is required'),
});

export const implantValidationSchema = Yup.object().shape({
  implantName: Yup.string().required('Implant Name is required'),
  sortNumber: Yup.number().required('Sort Number is required'),
});
