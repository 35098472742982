import { Box, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Colors } from '../../styles/theme';
import { VideoItem, VideoImage } from '../../styles/video';
import video3 from '../../assets/video3.svg';
import playbutton from '../../assets/Playbutton.svg';
import video2 from '../../assets/video2.png';
import PolygonLanding from '../../assets/downloadBrochurePolygon.svg';
import brochure1 from '../../assets/brochureMobile.png';
import brochure2 from '../../assets/brochure2.svg';
import brochurePoloygon from '../../assets/brochurePolygon.png';
import { TrademarkSymbol } from '../../styles/symbols';

export default function DownloadBrochureSection() {
  const onDownload = () => {
    const link = document.createElement('a');
    link.download = `brochure1.2.pdf`;
    link.href = 'brochure1.2.pdf';
    link.click();
  };
  return (
    <Box>
      <Box sx={{}}>
        <Grid container spacing={2} sx={{ border: 'solid 0px red', m: 0 }}>
          <Grid xs={12} md={6} sx={{ border: 'dotted 0px green', textAlign: 'center', order: { xs: 1, md: 0 } }}>
            <Box component="img" src={brochure1} sx={{ zIndex: '2', height: { sm: '200px', md: '400px' } }} />
          </Grid>
          <Grid xs={12} md={6} sx={{ border: 'dotted 0px green', pl: 4, pt: { xs: 1, md: 10 }, textAlign: { xs: 'center', md: 'left' } }}>
            <Box sx={{ pt: 5 }}>
              <Typography sx={{ fontWeight: 700, fontSize: { xs: '24px', md: '48px' }, lineHeight: '30px', display: 'inline' }}> Download </Typography>{' '}
              <Typography sx={{ fontWeight: 400, fontSize: { xs: '24px', md: '48px' }, lineHeight: '30px', display: 'inline' }}> Brochure</Typography>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Typography>Learn more about the Watson Extraction System{TrademarkSymbol()} and the "1-2 Punch for Well Fixed Stems"</Typography>
            </Box>

            <Button variant="contained" color="primary" onClick={onDownload} sx={{ textTransform: 'none', borderRadius: 100, mt: 4, mb: 2, py: '10px', px: '24px' }}>
              <Typography noWrap> Download PDF</Typography>
            </Button>
          </Grid>
          {/* <Box sx={{ position: 'relative', top: '-140px' }}>
            <Box component="img" src={brochurePoloygon} sx={{ width: '100%', bottom: '25px' }} />
          </Box> */}
        </Grid>
      </Box>
    </Box>
  );
}
