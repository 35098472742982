import { useState } from 'react';
import { Box, Typography, Button, Modal, Grid, Link } from '@mui/material';
import { Colors } from '../../styles/theme';
import Polygon5 from '../../assets/Polygon5.svg';
import Polygon6 from '../../assets/Polygon6.svg';
import OrderNow from '../../assets/ordernow1.png';
import { RegisterTextField, RegisterMultiLineTextField } from '../../styles/registerFormControls';
import { Controller, useForm } from 'react-hook-form';
import OrderModal from './OrderModal';
import { TrademarkSymbol } from '../../styles/symbols';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function PreOrderSection() {
  const [openOrderModal, setOpenOrderModal] = useState(false);

  return (
    <Box>
      <Box
        sx={{
          border: '0px dashed yellow',
          zIndex: '1',
          height: '485px',
          backgroundImage: `linear-gradient(101.16deg, rgba(103, 80, 164, 0.7) 0.95%, rgba(25, 103, 210, 0.7) 100%),url(${OrderNow})`,

          color: Colors.white,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography align="center" variant="h4" sx={{ p: { xs: '1', sm: '4' }, mt: 12 }}>
          Get the Watson Extraction System{TrademarkSymbol()}
        </Typography>
        <Typography align="left" variant="body1" sx={{ px: { xs: '1', sm: '4' }, py: 4 }}>
          {' '}
          Available in the United States. For more information click the Request Info button below or contact us at{' '}
          <Link href="mailto:sales@simplexortho.com" sx={{ color: '#FFFF' }}>
            sales@simplexortho.com
          </Link>{' '}
          or 678-348-6782
        </Typography>
        <Button
          variant="contained"
          color="secondaryBlue2"
          onClick={() => setOpenOrderModal(true)}
          sx={{ m: 2, px: 2, py: 1, textTransform: 'none', borderRadius: '100px', '&:hover': { backgroundColor: Colors.secondaryBlue1 } }}
        >
          <Typography noWrap sx={{ color: Colors.primaryBlue1 }}>
            {' '}
            Request Info{' '}
          </Typography>
        </Button>
      </Box>
      <OrderModal open={openOrderModal} handleClose={() => setOpenOrderModal(false)} />
    </Box>
  );
}
