import { Button, Box, Checkbox, Typography, FormControlLabel, Grid } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useState } from 'react';
import { CaseTextField, CaseComboBox, CaseDatePickerDesktop } from '../styles/caseFormControls';

export function AddedUser(props) {
  var user = props.user;

  const [state, setState] = useState({
    isActive: user.isActive,
    isAdmin: user.isAdmin,
  });
  const onAdminChange = (event) => {
    let isActive = state.isActive;
    let isAdmin = !state.isAdmin;

    props?.onChange(user.userId, isActive, isAdmin);
    setState({ ...state, isAdmin: isAdmin });
  };
  const onActivateChange = (event) => {
    let isActive = !state.isActive;
    let isAdmin = state.isAdmin;

    props?.onChange(user.userId, isActive, isAdmin);
    setState({ ...state, isActive: isActive });
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Button variant="text" endIcon={<RemoveCircleIcon />} onClick={() => props.removeUser(user.userId)}>
            {user.firstName + ' ' + user.lastName}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <FormControlLabel control={<Checkbox checked={state.isActive} onChange={onActivateChange} name="isActive" />} label="Activate user" />
            <FormControlLabel control={<Checkbox checked={state.isAdmin} onChange={onAdminChange} name="isAdmin" />} label="Make user admin" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export function AddedBlade(props) {
  var blade = props.blade;

  return (
    <Box sx={{ my: 2 }}>
      <Grid container>
        <Grid item xs={9}>
          {props.id + 1} SKU: {blade.sosSku}, Blade Desc: {blade.bladeDesc}, Count: {blade.count}
        </Grid>
        <Grid item xs={3}>
          <stack>
            <AddCircleIcon onClick={() => props.increment(blade)} />
            <RemoveCircleIcon onClick={() => props.decrement(blade)} />
          </stack>
        </Grid>
      </Grid>
    </Box>
  );
}
