import Layout from '../Layout';
import { DataGrid, GridToolbar, GridFooter } from '@mui/x-data-grid';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline, Button, Box, Container, Typography, Stack, Checkbox, CircularProgress, Backdrop , Autocomplete, TextField} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { CustomToolbar, CustomFooter, ClipboardPlusIcon } from '../../styles/caseList';
import { Colors } from '../../styles/theme';
import { LocalSee } from '@mui/icons-material';
import axiosInstance from '../../api/axios';
import { useEffect, useState } from 'react';
import { find } from 'lodash';
import { EditButton } from '../../styles/caseList';
import { CaseComboBox } from '../../styles/caseFormControls';

export default function Facilities() {

  const CUSTOMER ="Customer";
  const DISTRIBUTOR="Distributor";
  const OTHER ="Other";
  var reorderTypes = [
    { id: 0, desc: 'Prepayment' },
    { id: 1, desc: 'Invoice on Ship' },
    { id: 2, desc: 'Invoice on Receipt' },
    { id: 3, desc: 'Invoice on Receipt' },
  ];
  var facilityTypes = [ CUSTOMER, DISTRIBUTOR, OTHER]

  function getReorderType(params: GridValueGetterParams) {
    let reorderType = reorderTypes.find((i) => i.id == params.row.reOrderType).desc;
    return reorderType;
  }

  const [loading, setLoading] = useState(true);
  const [showCustomers, setShowCustomers] = useState(true);
  const [hospitals, setHospitals] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [facilityType, setFacilityType] = useState('Customer');

  const navigate = useNavigate();
  const showForm = (clickedRow) => {
    navigate('/RegisterFacility', { state: clickedRow });
  };
  const columns = [
    { field: 'action', headerName: 'Edit', sortable: false, renderCell: ({ row }) => <EditButton onClick={() => showForm(row)} />, disableClickEventBubbling: true },
    { field: 'sosCustomerId', headerName: 'Id', width: 50, editable: false },
    { field: 'hospitalName', headerName: 'Facility Name', width: 200, editable: false },
    { field: 'customerType', headerName: 'Customer Type', width: 150, editable: false },
   
    { field: 'email', headerName: 'Email', width: 200, editable: false },
    { field: 'phone', headerName: 'Phone', width: 120, editable: false },
    { field: 'address1', headerName: 'Address1', width: 200, editable: false },
    { field: 'address2', headerName: 'Address2', width: 200, editable: false },
    { field: 'city', headerName: 'City', width: 120, editable: false },
    { field: 'state', headerName: 'State', width: 120, editable: false },
    { field: 'zip5', headerName: 'Zip Code', width: 120, editable: false },
    { field: 'sosSalesRepId', headerName: 'Sales Rep Id', width: 150, editable: false },
   
  ];

  const syncFacility = async () => {
    setLoading(true);
    try {
      var resp = await axiosInstance.get('api/Sos/SyncFacilities');
      var data = resp.data;
      console.log('syncfacility %o', data);
      getAllHospitals();
    } catch (error) {
      console.error(error.message);
    }
    setLoading(false);
  };

  const getAllHospitals = async () => {
    setLoading(true);
    try {
      // const  response = await axiosInstance.get('http://ec2-44-203-70-77.compute-1.amazonaws.com:800/api/Case');
      const response = await axiosInstance.get('api/Case/GetHospitals');
      if (response) {
        var hospitals = response.data.map((x, index) => ({
          ...x,
          id: x.sosCustomerId,
          rowId: index,
        }));
        setHospitals(hospitals);
        setFacilities(hospitals.filter(i=>i.customerType=="Customer") );
        setFacilityType('Customer');

      } else console.log('no response');
    } catch (error) {
      console.error(error.message);
    }
    setLoading(false);
  };

  const toggleCustomers = async() =>{

    try{
      var showFlag = !showCustomers;
      setShowCustomers(showFlag);
      setFacilities( showFlag ? hospitals.filter(i=>i.customerType=="Customer") : hospitals.filter(i=>i.customerType!="Customer") );  

    }
    catch (error) {
      console.error(error.message);
    }
   };
 
   const filterByFacilityType = async(facilityType) =>{
    var fileteredFacilities = [];
    try{
      switch(facilityType){
        case  CUSTOMER:  fileteredFacilities = hospitals.filter(i=>i.customerType==CUSTOMER) ;
        break;
        case DISTRIBUTOR:fileteredFacilities = hospitals.filter(i=>i.customerType==DISTRIBUTOR) ;
        break;
        default:
          fileteredFacilities = hospitals.filter(i=>i.customerType!=CUSTOMER && i.customerType!= DISTRIBUTOR) ;
      }
      setFacilities( fileteredFacilities );  

    }
    catch (error) {
      console.error(error.message);
    }
   };

  useEffect(() => {
    console.log('useEffect');
    getAllHospitals();
  }, []);

  return (
    <Layout>
      <>
        <Box display="flex" sx={{ mx: 2, my: 2, flexWrap: 'wrap' }}>
          <Typography sx={{ fontWeight: 700, fontSize: '22px', lineHeight: '28px', flexGrow: 3 }}>Facilities Admin Console</Typography>
          <Autocomplete disablePortal  id="combo-box-demo"  options={facilityTypes} value={facilityType} onChange={(e, val)=>{setFacilityType(val); filterByFacilityType(val); }} sx={{ width: '170px', mx:1,backgroundColor: Colors.white }}  renderInput={(params) => <TextField {...params} label="Facility Type" />}/>
        
          <Button variant="contained" onClick={syncFacility} sx={{ mr: 2, fontSize: '14px', lineHeight: '20px', textTransform: 'none' }}>
            Sync Facilities
          </Button>
        </Box>

        <Box sx={{ height: 700, mx: 2 }}>
          <DataGrid
            editMode="row"
            rows={facilities}
            columns={columns}
            pageSize={12}
            rowsPerPageOptions={[12]}
            components={{ Toolbar: CustomToolbar, Footer: CustomFooter }}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'hospitalName', sort: 'asc' }],
              },
            }}
            sx={{ backgroundColor: Colors.white, '& MuiDataGrid-footerContainer': { backGroundColor: Colors.black } }}
          />
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    </Layout>
  );
}
