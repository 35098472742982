import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextField, Button, Box, Typography, Grid, Stack, FormLabel, FormControl, FormControlLabel, Select, MenuItem, Checkbox } from '@mui/material';
import { Colors } from '../styles/theme';
import { register } from '../slices/auth';
import { clearMessage } from '../slices/message';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Layout from './Layout';

import axiosInstance from '../api/axios';

import { AddedUser } from '../styles/facility';

const AdminUsers = (hospitalId) => {
  const [regType, setRegType] = useState(1);
  const [userData, setUserData] = useState([]);
  const [userSelected, setUserSelected] = React.useState('');
  const [usersAdded, setUsersAdded] = useState([]);
  const [successful, setSuccessful] = useState(false);

  const location = useLocation();
  const state = location.state;

  const navigate = useNavigate();

  hospitalId = state?.hospitalId;

  useEffect(() => {
    const url = 'api/Case/GetUsers';
    console.log('useEffect');
    axiosInstance
      .get(url)
      .then(function (innerResponse) {
        setUserData(innerResponse.data);
      })
      .catch(function (innerResponse) {});

    const url2 = 'api/Case/GetUsersByHospitalId';
    axiosInstance
      .get(url2, { params: { id: hospitalId } })
      .then(function (innerResponse) {
        if (innerResponse.data) {
          console.log('resp: %o', innerResponse.data);
          setUsersAdded(innerResponse.data);
        }
      })
      .catch(function (innerResponse) {
        console.log(innerResponse);
      });
  }, []);

  const onUserChange = (event) => {
    setUserSelected(event.target.value);
  };

  const addUser = () => {
    if (!usersAdded.find((i) => i.userId == userSelected)) {
      var user = userData.find((u) => u.userId == userSelected);
      console.log(user);
      var userObj = { userId: user.userId, hospitalId: hospitalId, isActive: false, isAdmin: false, firstName: user.firstName, lastName: user.lastName };
      console.log(userObj);
      setUsersAdded((prev) => [...prev, userObj]);
      console.log('usersAdded: %o', usersAdded);
    }
  };

  const removeUser = (userId) => {
    setUsersAdded(usersAdded.filter((item) => item.userId != userId));
  };

  const handleChange = (userId, isActive, isAdmin) => {
    let users = usersAdded.map((item) => {
      return item.userId == userId ? { ...item, isActive: isActive, isAdmin: isAdmin } : { ...item };
    });
    setUsersAdded(users);
  };

  const saveAdminSettings = async () => {
    console.log('saveAdminSettings %o', usersAdded);

    try {
      var resp = await axiosInstance.post('api/Case/UpdateAdminUsers', usersAdded);
      console.log(resp.data);
      navigate('/facilities');
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <Layout>
      <Box display="flex" sx={{ mx: 4, my: 1, flexWrap: 'wrap', border: '0px dashed red' }}>
        <Typography sx={{ fontWeight: 700, fontSize: '22px', lineHeight: '28px', flexGrow: 3 }}>Facility Admin Console</Typography>
      </Box>
      <Box display="flex" sx={{ my: 2, border: '0px dashed blue', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', border: '0px dashed yellow', backgroundColor: Colors.white, px: { xs: 4, sm: 30 } }}>
          <Box sx={{ my: 8, display: 'flex', width: { xs: '300px', sm: '450px' }, flexDirection: 'column', alignItems: 'left', border: '0px dashed yellow' }}>
            <Typography align="left" sx={{ fontSize: '30px', lineHeight: '35px', fontWeight: '700' }}>
              Add Users
            </Typography>
            <Box component="form" noValidate sx={{ mt: 4, border: '0px dashed green' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Box sx={{ mr: 3, pb: 2, border: '0px dashed red' }}>
                    <Typography sx={{ fontSize: '16px', lineHeight: '19px', fontWeight: '400' }}> Users</Typography>
                    <Select value={userSelected} onChange={onUserChange} fullWidth>
                      {userData.map((item, index) => (
                        <MenuItem key={index} value={item.userId}>
                          {item.firstName + ' ' + item.lastName}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Button color="primary" variant="text" onClick={addUser} sx={{ height: '50px', textTransform: 'none' }}>
                    + Add user
                  </Button>
                </Grid>

                <Grid item xs={12} md={12}>
                  {usersAdded.map((item, index) => {
                    console.log('item %o', item);
                    return <AddedUser key={index} user={item} removeUser={removeUser} onChange={handleChange} />;
                  })}
                </Grid>
              </Grid>
              <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Button color="primary" variant="contained" onClick={saveAdminSettings} sx={{ mt: 2, mb: 3, textTransform: 'none', borderRadius: '100px' }}>
                  Save Users
                </Button>
                <Button color="primary" variant="text" onClick={() => navigate('/facilities')} sx={{ mx: 2, mt: 2, mb: 3, textTransform: 'none' }}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default AdminUsers;
