import Layout from '../Layout';
import { DataGrid, GridToolbar, GridFooter } from '@mui/x-data-grid';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline, Button, Box, Container, Typography, Stack, Checkbox } from '@mui/material';

import { Link, useNavigate } from 'react-router-dom';
import { CustomToolbar, CustomFooter, ClipboardPlusIcon } from '../../styles/caseList';
import { Colors } from '../../styles/theme';
import { LocalSee } from '@mui/icons-material';
import axiosInstance from '../../api/axios';
import { useEffect, useState } from 'react';
import { find } from 'lodash';
import { EditButton } from '../../styles/caseList';

export default function Users() {
  var userTypes = [
    { id: 1, desc: 'Sales Representative' },
    { id: 2, desc: 'Administrator' },
    { id: 3, desc: 'Facility Administrator' },
    { id: 4, desc: 'Surgeon' },
  ];
  function getUserType(params: GridValueGetterParams) {
    let userType = userTypes.find((i) => i.id == params.row.userTypeId).desc;
    return userType;
  }
  const handleActivatedChange = (clickedRow) => {
    console.log(JSON.stringify(clickedRow));
    const updatedUsers = users.map((item) => {
      console.log('clicked Row' + JSON.stringify(clickedRow));
      if (item.rowId === clickedRow.rowId) {
        return { ...item, isApprovedForLogin: !clickedRow.isApprovedForLogin };
      }
      return item;
    });
    console.log(updatedUsers);
    setUsers(updatedUsers);
  };

  const navigate = useNavigate();
  const showForm = (clickedRow) => {
    //alert(JSON.stringify(clickedRow));
    navigate('/ModifyUser', { state: clickedRow });
  };
  const columns = [
    {
      field: 'action',
      headerName: 'Edit',
      sortable: false,
      renderCell: ({ row }) => (
        // <Button Variant="outlined" endIcon={<Edit />} onClick={() => showForm(row)}>
        //   {' '}
        //   Edit{' '}
        // </Button>
        <EditButton onClick={() => showForm(row)} />
      ),
      disableClickEventBubbling: true,
    },

    { field: 'userName', headerName: 'Username', width: 120, editable: false },
    { field: 'userType', headerName: 'User Type', width: 120, editable: false, valueGetter: getUserType },
    { field: 'isApprovedForLogin', headerName: 'Activated', type: 'boolean', width: 120, editable: false },
    { field: 'firstName', headerName: 'FirstName', width: 120, editable: false },
    { field: 'lastName', headerName: 'LastName', width: 120, editable: false },
    { field: 'email', headerName: 'Email', width: 120, editable: false },
    { field: 'phone', headerName: 'Phone', width: 120, editable: false },
    { field: 'address1', headerName: 'Address1', width: 120, editable: false },
    { field: 'address2', headerName: 'Address2', width: 120, editable: false },
    { field: 'city', headerName: 'City', width: 120, editable: false },
    { field: 'state', headerName: 'State', width: 120, editable: false },
    { field: 'zip5', headerName: 'Zip Code', width: 120, editable: false },
    { field: 'id', headerName: 'User ID', width: 30, editable: false },
  ];

  const rows = [{ id: 1, username: 'Apr 4,2022', firstName: 'DePuy', lastName: 'Actis', email: 'Size 8', phone: 'High Offset', address1: '20mm' }];

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const getAllUsers = async () => {
      setLoading(true);
      try {
        // const  response = await axiosInstance.get('http://ec2-44-203-70-77.compute-1.amazonaws.com:800/api/Case');
        const response = await axiosInstance.get('api/Case/GetUsers');
        if (response) {
          var users = response.data.map((x, index) => ({
            ...x,
            id: x.userId,
            rowId: index,
          }));
          setUsers(users);
        } else console.log('no response');
        console.log(users);
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };
    getAllUsers();
  }, []);

  return (
    <Layout>
      {loading && <div>Loading</div>}
      {!loading && (
        <>
          <Box display="flex" sx={{ mx: 2, my: 2, flexWrap: 'wrap' }}>
            <Typography sx={{ fontWeight: 700, fontSize: '22px', lineHeight: '28px', flexGrow: 3 }}>Users Admin Console</Typography>
            <Button variant="contained" component={Link} to="/RegisterUser" sx={{ mr: 2, fontSize: '14px', lineHeight: '20px', textTransform: 'none' }}>
              Add User
            </Button>
          </Box>

          <Box sx={{ height: 540, mx: 2 }}>
            <DataGrid
              editMode="row"
              rows={users}
              columns={columns}
              pageSize={8}
              rowsPerPageOptions={[8]}
              components={{ Toolbar: CustomToolbar, Footer: CustomFooter }}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              sx={{ backgroundColor: Colors.white, '& MuiDataGrid-footerContainer': { backGroundColor: Colors.black } }}
            />
          </Box>
        </>
      )}
    </Layout>
  );
}
