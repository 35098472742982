import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, Formik, Field, Form, ErrorMessage, setFieldValue } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Box, Typography, Grid, Stack, FormLabel, FormControl, FormControlLabel, Select, MenuItem } from '@mui/material';
import { Colors } from '../../styles/theme';
import { register } from '../../slices/auth';
import { clearMessage } from '../../slices/message';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../Layout';
import { LoginLabel } from '../../styles/login';
import { RegisterTextField } from '../../styles/registerFormControls';
import axiosInstance from '../../api/axios';
import AuthService from '../../api/authService';

const RegisterUser = () => {
  const [regType, setRegType] = useState(1);

  const [successful, setSuccessful] = useState(false);

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    userTypeId: 1,
    firstname: '',
    lastname: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip5: '',
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .test('len', 'The username must be between 3 and 20 characters.', (val) => val && val.toString().length >= 3 && val.toString().length <= 20)
      .required('This field is required!'),
    email: Yup.string().email('This is not a valid email.').required('This field is required!'),
    password: Yup.string()
      .test('len', 'The password must be between 6 and 40 characters.', (val) => val && val.toString().length >= 6 && val.toString().length <= 40)
      .required('This field is required!'),
    confirmPassword: Yup.string()
      .test('len', 'The Confirm password must be between 6 and 40 characters.', (val) => val && val.toString().length >= 6 && val.toString().length <= 40)
      .required('This field is required!')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const handleRegister = (formValue) => {
    const { username, email, password } = formValue;

    setSuccessful(false);

    dispatch(register({ username, email, password }))
      .unwrap()
      .then(() => {
        setSuccessful(true);
      })
      .catch(() => {
        setSuccessful(false);
      });
  };

  const handleSubmit = async (values) => {
    var userObj = values;
    values.userTypeId = regType;

    console.log('userObj ' + JSON.stringify(userObj));
    try {
      //var resp= await axiosInstance.post('http://ec2-44-203-70-77.compute-1.amazonaws.com:800/api/Case', caseObj );

      //var resp= await axiosInstance.post('api/Case/AddUser', userObj );
      var resp = await AuthService.register(userObj);
      console.log(resp.data);
      navigate('/RegisterUserConfirmed', { state: resp.data });
    } catch (error) {
      console.error(error.message);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Layout>
      <Box display="flex" sx={{ my: 10, border: '0px dashed blue', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', border: '0px dashed yellow', backgroundColor: Colors.white, px: { xs: 4, sm: 30 } }}>
          <Box sx={{ my: 8, display: 'flex', width: { xs: '300px', sm: '350px' }, flexDirection: 'column', alignItems: 'left', border: '0px dashed yellow' }}>
            <Typography align="left" sx={{ fontSize: '30px', lineHeight: '35px', fontWeight: '700' }}>
              {' '}
              User Registration{' '}
            </Typography>
            <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 4, border: '0px dashed green' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstname"
                    label="FirstName"
                    name="firstname"
                    value={formik.values.firstname}
                    autoFocus
                    sx={{ 'label + &': { mt: 2 } }}
                    onChange={formik.handleChange}
                    error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                    helperText={formik.touched.firstname && formik.errors.firstname}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastname"
                    label="Lastname"
                    name="lastname"
                    value={formik.values.lastname}
                    autoFocus
                    sx={{ 'label + &': { mt: 2 } }}
                    onChange={formik.handleChange}
                    error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                    helperText={formik.touched.lastname && formik.errors.lastname}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="UserName"
                    name="username"
                    value={formik.values.username}
                    autoFocus
                    sx={{ 'label + &': { mt: 2 } }}
                    onChange={formik.handleChange}
                    error={formik.touched.username && Boolean(formik.errors.username)}
                    helperText={formik.touched.username && formik.errors.username}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    value={formik.values.email}
                    autoFocus
                    sx={{ 'label + &': { mt: 2 } }}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    label="Phone"
                    name="phone"
                    value={formik.values.phone}
                    autoFocus
                    sx={{ 'label + &': { mt: 2 } }}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ mr: 3, pb: 2, border: '0px dashed red' }}>
                    <Typography sx={{ fontSize: '16px', lineHeight: '19px', fontWeight: '400' }}> Registration Type</Typography>

                    <Select name="regType" value={regType} onChange={(e) => setRegType(e.target.value)} sx={{ width: '100%' }}>
                      <MenuItem value={1}>Sales Representative</MenuItem>
                      <MenuItem value={3}>Facility Admin</MenuItem>
                      <MenuItem value={4}>Surgeon</MenuItem>
                      <MenuItem value={2}>Admin</MenuItem>
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    id="address1"
                    label="Address"
                    name="address1"
                    value={formik.values.address1}
                    autoFocus
                    sx={{ 'label + &': { mt: 2 } }}
                    onChange={formik.handleChange}
                    error={formik.touched.address1 && Boolean(formik.errors.address1)}
                    helperText={formik.touched.address1 && formik.errors.address1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    id="city"
                    label="City"
                    name="city"
                    value={formik.values.city}
                    autoFocus
                    sx={{ 'label + &': { mt: 2 } }}
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    id="state"
                    label="State"
                    name="state"
                    value={formik.values.state}
                    autoFocus
                    sx={{ 'label + &': { mt: 2 } }}
                    onChange={formik.handleChange}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    id="zip5"
                    label="Zip Code"
                    name="zip5"
                    value={formik.values.zip5}
                    autoFocus
                    sx={{ 'label + &': { mt: 2 } }}
                    onChange={formik.handleChange}
                    error={formik.touched.zip5 && Boolean(formik.errors.zip5)}
                    helperText={formik.touched.zip5 && formik.errors.zip5}
                  />
                </Grid>
              </Grid>

              <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2, mb: 3, height: '50px', textTransform: 'none' }}>
                {' '}
                Submit{' '}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default RegisterUser;
