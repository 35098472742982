import { Box, Typography, Button, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AboutImage, KeyFeatureItem } from '../../styles/keyFeature';
import KeyFeatureIcon from '../../assets/KeyFeatureIcon.svg';
import { Colors } from '../../styles/theme';
import { useMediaQuery } from '@mui/material';
import theme from '../../styles/theme';
import bladesOverlap from '../../assets/home/bladesOverlap.png';
import calcarsparing from '../../assets/home/calcarsparing.png';
import bonesparing from '../../assets/home/bonesparing.png';
import tronchantersparing from '../../assets/home/tronchantersparing-combined.png';
import widerange from '../../assets/home/widerange-combined1.png';

export default function KeyFeatures() {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{ background: '#FFFFFF' }}>
      <Box sx={{ textAlign: 'center', mt: 6 }}>
        <Typography sx={{ fontWeight: 700, fontSize: '48px', lineHeight: '30px', display: 'inline' }}> Key </Typography>{' '}
        <Typography sx={{ fontWeight: 400, fontSize: '48px', lineHeight: '30px', display: 'inline' }}> Features</Typography>
      </Box>
      <Box
        sx={{
          p: { xs: 4, md: 6 },

          fontSize: { xs: '12px', md: '14px' },
        }}
      >
        <Grid container spacing={{ xs: 0, sm: 2 }} sx={{ border: 'solid 0px red', m: 0 }}>
          <Grid xs={5} sm={3} sx={{ border: 'dotted 0px green' }}>
            <KeyFeatureItem
              title="Blades Overlap"
              body="No Anterior or Posterior blade required. Substantially curved blades allow for Anterior and Posterior coverage"
              KeyFeatureIcon={bladesOverlap}
            />
          </Grid>
          <Grid xs={2} sm={1} sx={{ border: 'dotted 0px green', textAlign: 'center' }}>
            <Box sx={{ borderLeft: '1px solid #CFCFCF', my: 2, display: 'inline-block', height: '90%' }}></Box>
          </Grid>
          <Grid item xs={5} sm={3} sx={{ border: 'dotted 0px green' }}>
            <KeyFeatureItem
              title="Trochanter sparing"
              body="Curved lateral blade shape and Straight Lateral blade tip feature avoid interference with trochanter"
              KeyFeatureIcon={tronchantersparing}
            />
          </Grid>
          <Grid xs={12} sm={1} sx={{ border: 'dotted 0px green', textAlign: 'center' }}>
            <Box sx={{ borderLeft: '1px solid #CFCFCF', my: 2, display: 'inline-block', height: '90%' }}></Box>
          </Grid>
          <Grid item xs={12} sm={3} sx={{ border: 'dotted 0px green' }}>
            <KeyFeatureItem
              title="Calcar sparing"
              body="Straight Medial blade tip thickness and curved geometry concentrates force on the contour of the stem"
              KeyFeatureIcon={calcarsparing}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ border: 'solid 0px red', mt: 2 }}>
          <Grid item xs={12} sm={2} sx={{ border: 'dotted 0px green' }}></Grid>
          <Grid item xs={12} sm={3} sx={{ border: 'dotted 0px green' }}>
            <KeyFeatureItem title="Bone sparing" body='Substantially curved 1.5mm cutting surfaces supports our "Bone Conserving Technology"' KeyFeatureIcon={bonesparing} />
          </Grid>
          <Grid xs={12} sm={1} sx={{ border: 'dotted 0px green', textAlign: 'center' }}>
            <Box sx={{ borderLeft: '1px solid #CFCFCF', my: 2, display: 'inline-block', height: '90%' }}></Box>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ border: 'dotted 0px green' }}>
            {/* <KeyFeatureItem title="Customized sizing" body="51 blades designed to fit tightly around different sized stems" KeyFeatureIcon={widerange} /> */}

            <Box sx={{ border: '0px dotted red' }}>
              <Box sx={{ textAlign: 'center' }}>
                <Box sx={{ height: { sm: '125px', md: '150px', lg: '200px' } }}>
                  <Box component="img" src={widerange} sx={{ width: '100%' }} />
                </Box>

                {/* <AboutImage src={KeyFeatureIcon} /> */}
                <Typography sx={{ fontSize: '24px', lineHeight: '32px', fontWeight: '700' }} gutterBottom>
                  Wide Range of Sizes
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ justifySelf: 'start' }}>
                  51 blades designed to accomodate most non-cemented femoral stem shapes and sizes.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ border: 'dotted 0px green' }}></Grid>
        </Grid>
      </Box>
    </Box>
  );
}
