import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import { CaseTextField } from '../styles/caseFormControls';
import { RegisterTextField, RegisterMultiLineTextField } from '../styles/registerFormControls';
import { TextField, InputLabel, InputBase, Grid, Alert } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Layout from './Layout';
import { useMediaQuery } from '@mui/material';
import theme from '../styles/theme';
import { Colors } from '../styles/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axios';
import { reorderValidationSchema } from '../validations/validationSchema';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AuthService from '../api/authService';
import dayjs from 'dayjs';
import BladeSelectionModal from './BladeSelectionModal';

export default function CaseConfirm() {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  //  const {state}= props;
  const user = AuthService.getCurrentUser();
  const location = useLocation();
  const navigate = useNavigate();
  const reorderObj = location.state;
  console.log('reorder %o', reorderObj);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [notes, setNotes] = useState('');
  const [salesRep, setsalesRep] = useState('');
  const [isTrial, setIsTrial] = useState(false);
  const [salesRepErrorMsg, setSalesRepErrorMsg] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip5, setZip5] = useState('');

  useEffect(() => {
    setNotes(reorderObj?.notes);
    setsalesRep(reorderObj?.salesRep);
    setIsTrial(reorderObj?.isTrial);
    setAddress1(reorderObj?.address1);
    setAddress2(reorderObj?.address2);
    setCity(reorderObj?.city);
    setState(reorderObj?.state);
    setZip5(reorderObj?.zip5);
  }, []);

  const onSubmit = async () => {
    if (salesRep == '') {
      setSalesRepErrorMsg('Please Provide Sales Rep Name');
      return;
    }

    try {
      reorderObj.notes = notes;
      reorderObj.isTrial = isTrial;
      reorderObj.salesRep = salesRep;

      reorderObj.address1 = address1;
      reorderObj.address2 = address2;
      reorderObj.city = city;
      reorderObj.state = state;
      reorderObj.zip5 = zip5;

      console.log('reorderObj %o', reorderObj);
      var resp = await axiosInstance.post('api/Case/AddCase', reorderObj);
      var data = resp.data;
      console.log('resp of addcase %o', data);
      navigate('/ReOrderList');
    } catch (error) {
      console.error(error.message);
    }
  };

  const onBack = async () => {
    try {
      reorderObj.notes = notes;
      reorderObj.isTrial = isTrial;
      reorderObj.salesRep = salesRep;

      reorderObj.address1 = address1;
      reorderObj.address2 = address2;
      reorderObj.city = city;
      reorderObj.state = state;
      reorderObj.zip5 = zip5;

      console.log('reorderObj %o', reorderObj);

      navigate('/CaseSubmit', { state: reorderObj });
    } catch (error) {
      console.error(error.message);
    }
  };

  const onError = (errors, e) => console.log(errors);

  const toggleTrial = () => {
    if (!isTrial) {
      const url = 'api/Case/GetDistributorByHospitalId';
      axiosInstance
        .get(url, { params: { id: reorderObj.hospitalId } })
        .then(function (innerResponse) {
          console.log('hospital info %o', innerResponse.data);
          if (innerResponse.data) {
            setAddress1(innerResponse.data.address1);
            setAddress2(innerResponse.data.address2);
            setCity(innerResponse.data.city);
            setState(innerResponse.data.state);
            setZip5(innerResponse.data.zip5);
          }
        })
        .catch(function (innerResponse) {
          console.log(innerResponse);
        });
    }
    setIsTrial(!isTrial);
  };

  const defaultValues = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip5: '',
    zip4: '',
    submittedBy: user?.userName,
  };

  const reorderValidationSchema = Yup.object().shape({
    address1: Yup.string().required('Ship To Address1 is required'),
    city: Yup.string().required('Ship To City is required'),
    state: Yup.string().required('Ship To State is required'),
    zip5: Yup.string().required('Ship To Zip is required'),
  });
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onChange', defaultValues, resolver: yupResolver(reorderValidationSchema) });

  return (
    <Layout>
      <Box display="flex" sx={{ mx: 2, my: 2, border: '1px dashed blue', justifyContent: { xs: 'left', sm: 'center' } }}>
        <Box sx={{ display: 'flex', border: '1px dashed yellow', backgroundColor: Colors.white, width: '100%', px: { xs: 1, sm: 30 } }}>
          <Box sx={{ my: 2, display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'left', border: '0px dashed yellow' }}>
            <Typography sx={{ fontSize: '30px', lineHeight: '36px', fontWeight: 700, mb: 2 }}>Confirm Order Details</Typography>

            <Typography sx={{ mt: 0 }}>Facility: {reorderObj.hospitalName}</Typography>
            <Typography sx={{}}>Surgeon: {reorderObj.surgeon}</Typography>
            <Typography>Surgery Date: {dayjs(reorderObj.surgeryDate).format('MM/DD/YYYY')}</Typography>

            <Box sx={{ display: 'flex', my: 2 }}>
              <Typography sx={{ fontSize: '22px', lineHeight: '28px', fontWeight: '500', flexGrow: 1 }}> Items To Reorder: </Typography>
            </Box>

            <Box>
              {reorderObj.caseDetails?.map((item, index) => (
                <Typography key={index} sx={{ whiteSpace: { xs: 'normal', sm: 'pre' }, fontSize: { xs: '12px', sm: '18px' } }}>
                  {' '}
                  {item.sosSku + '  ' + item.bladeDesc + ',  Count: ' + item.count}
                </Typography>
              ))}
            </Box>

            <Box sx={{ maxWidth: { xs: '335px', sm: '600px' }, mt: 2 }}>
              <RegisterMultiLineTextField label="Notes/Special Instructions" value={notes} onChange={(e) => setNotes(e.target.value)} rows={4} />
            </Box>
            <Box sx={{ mt: 1 }}>
              <FormControlLabel control={<Checkbox checked={isTrial} onChange={toggleTrial} name="isTrial" />} label="Is Trial" />
            </Box>
            {isTrial && (
              <Box sx={{ maxWidth: { xs: '335px', sm: '625px' }, mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <RegisterTextField id="address1" onChange={(e) => setAddress1(e.target.value)} value={address1} label="Ship To Address1" />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <RegisterTextField id="address2" onChange={(e) => setAddress2(e.target.value)} value={address2} label="Ship To Address2" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RegisterTextField id="city" onChange={(e) => setCity(e.target.value)} value={city} label="Ship To City" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RegisterTextField id="state" onChange={(e) => setState(e.target.value)} value={state} label="Ship To State" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RegisterTextField id="zip5" onChange={(e) => setZip5(e.target.value)} value={zip5} label="Ship To Zip" />
                  </Grid>
                </Grid>
              </Box>
            )}
            <Box sx={{ mt: 1 }}>
              <CaseTextField label="Sales Rep Name" value={salesRep} onChange={(e) => setsalesRep(e.target.value)} />
            </Box>
            {salesRepErrorMsg != '' && (
              <Alert icon={false} severity="error">
                {' '}
                {salesRepErrorMsg}{' '}
              </Alert>
            )}

            <Box display="flex" sx={{ mt: 5, flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'flex-start', border: '0px dashed blue' }}>
              <Button variant="contained" color="primary" onClick={onSubmit} sx={{ textTransform: 'none', borderRadius: 100, px: 4, py: 1 }}>
                <Typography noWrap> Submit</Typography>
              </Button>
              <Button variant="text" color="primary" onClick={onBack} sx={{ ml: { xs: 0, sm: 2 }, mt: { xs: 2, sm: 0 }, px: 2, py: 1, textTransform: 'none', borderRadius: 100 }}>
                <Typography noWrap> Back</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}
