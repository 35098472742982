import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';

const AddButton = ({ onExecute }) => (
  <div style={{ textAlign: 'center' }}>
    <Button color="primary" onClick={onExecute} title="Create new row">
      New
    </Button>
  </div>
);

const EditButton = ({ onExecute }) => (
  <Button variant="outlined" endIcon={<EditIcon />} onClick={onExecute} sx={{ border: 'solid 1px #1972D6', mr: 1 }}>
    {' '}
    Edit{' '}
  </Button>
);

const DeleteButton = ({ onExecute }) => (
  <Button variant="outlined" endIcon={<DeleteIcon />} onClick={onExecute} sx={{ border: 'solid 1px #1972D6', mr: 1 }}>
    {' '}
    Delete{' '}
  </Button>
);

const CommitButton = ({ onExecute }) => (
  <Button variant="outlined" endIcon={<SaveIcon />} onClick={onExecute} sx={{ border: 'solid 1px #1972D6', mr: 1 }}>
    {' '}
    Save{' '}
  </Button>
);

const CancelButton = ({ onExecute }) => (
  <Button variant="outlined" endIcon={<CancelIcon />} onClick={onExecute} sx={{ border: 'solid 1px #1972D6', mr: 1 }}>
    {' '}
    Cancel{' '}
  </Button>
);

export const commandComponents = {
  add: AddButton,
  edit: EditButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton,
};

export const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id];
  return <CommandButton onExecute={onExecute} />;
};
