import { Box, Typography, Button, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AboutImage, OsteotomeItem } from '../../styles/keyFeature';
import KeyFeatureIcon from '../../assets/KeyFeatureIcon.svg';
import { Colors } from '../../styles/theme';
import { useMediaQuery } from '@mui/material';
import theme from '../../styles/theme';
import straightLateral from '../../assets/home/straight-lateral.png';
import straightMedial from '../../assets/home/straight-medial.png';
import curvedlateral from '../../assets/home/curved-lateral.png';
import bonesparing from '../../assets/home/bonesparing.png';
import tronchantersparing from '../../assets/home/tronchantersparing-combined.png';
import { TrademarkSymbol } from '../../styles/symbols';

export function Osteotomes() {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{ background: '#FFFFFF' }}>
      <Box sx={{ textAlign: 'center', mt: 6 }}>
        <Typography sx={{ fontWeight: 700, fontSize: { xs: '24px', sm: '48px' }, lineHeight: '30px', display: 'inline' }}> Watson Extraction System{TrademarkSymbol()} </Typography>{' '}
        <Typography sx={{ fontWeight: 400, fontSize: { xs: '24px', sm: '48px' }, lineHeight: '30px', display: 'inline' }}> Osteotomes</Typography>
      </Box>
      <Box
        sx={{
          p: { xs: 4, md: 6 },

          fontSize: { xs: '12px', md: '14px' },
        }}
      >
        <Grid container spacing={{ xs: 0, sm: 2 }} sx={{ border: 'solid 0px red', m: 0 }}>
          <Grid xs={12} sm={3} sx={{ border: 'dotted 0px green' }}>
            <OsteotomeItem title="Straight Lateral" body=" Blade for Fit & Fill Stems" KeyFeatureIcon={straightLateral} />
          </Grid>

          <Grid xs={0} sm={1} sx={{ border: 'dotted 0px green', textAlign: 'center' }}>
            <Box sx={{ borderLeft: '1px solid #CFCFCF', my: 2, display: { xs: 'none', sm: 'inline-block' }, height: '90%' }}></Box>
          </Grid>

          <Grid item xs={12} sm={4} sx={{ border: 'dotted 0px green' }}>
            <OsteotomeItem title="Straight Medial" body=" Blade for both Fit & Fill and Tapered Wedge Stems" KeyFeatureIcon={straightMedial} />
          </Grid>
          <Grid xs={0} sm={1} sx={{ border: 'dotted 0px green', textAlign: 'center' }}>
            <Box sx={{ borderLeft: '1px solid #CFCFCF', my: 2, display: { xs: 'none', sm: 'inline-block' }, height: '90%' }}></Box>
          </Grid>
          <Grid item xs={12} sm={3} sx={{ border: 'dotted 0px green' }}>
            <OsteotomeItem title="Curved Lateral" body=" Blade for Tapered Wedge Stems" KeyFeatureIcon={curvedlateral} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
