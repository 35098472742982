import axios from "axios";
import axiosInstance  from "./axios";

const API_URL = "http://localhost:3000/api/auth/";

const register = (userObj) => {

  return axiosInstance.post('api/Case/AddUser', userObj );
};

const login = (username, password) => {

 return axiosInstance.post('api/Case/ValidateLogin', {username, password} )
      .then((response) => {
        console.log( 'validateLogin : %o',response.data );
      if (response.data.userName) {
        if(response.data.isApprovedForLogin===true)
        sessionStorage.setItem("user", JSON.stringify(response.data));
      }
      console.log(response.data);
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
};

const logout = () => {
  sessionStorage.removeItem("user");
  // return axios.post(API_URL + "signout").then((response) => {
  //   return response.data;
  // });
};

const getCurrentUser = () => {
  return JSON.parse(sessionStorage.getItem("user"));
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
}

export default AuthService;