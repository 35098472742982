import Layout from './Layout';
import { DataGrid,GridToolbar , GridFooter  } from '@mui/x-data-grid';
import { createTheme, ThemeProvider, responsiveFontSizes  } from '@mui/material/styles';
import {  CssBaseline, Button, Box, Container, Typography, Stack } from "@mui/material";
import {Link} from 'react-router-dom'
import {CustomToolbar,CustomFooter,ClipboardPlusIcon} from '../styles/caseList'
import {Colors} from '../styles/theme'
import { LocalSee } from '@mui/icons-material';
import axiosInstance from '../api/axios';
import { useEffect, useState } from 'react';

const columns = [
    
  { field: 'id', headerName: 'ID', width: 120 ,editable: false,},
   { field: 'surgeon', headerName: 'Surgeon', width: 120 ,editable: false,},
    { field: 'surgeryDate', headerName: 'Surgery Date', width: 120 ,editable: false,},
    { field: 'hospitalName',  headerName: 'Hospital',  width: 120, editable: false, },
    { field: 'medicalRecord1',  headerName: 'Medical Record 1',  width: 120, editable: false, },
    { field: 'medicalRecord2',  headerName: 'Medical Record 2',  width: 120, editable: false, },
    { field: 'medicalRecord3',  headerName: 'Medical Record 3',  width: 120, editable: false, },
   
    
    
  ];
  
  const rows = [
    {id:1, surgeryDate: 'Apr 4,2022', manufacturer: 'DePuy', implant: 'Actis', size: 'Size 8',implayType:'High Offset',medialBladeUsed:'20mm',lateralBladeUsed:'22mm',extendedLateralBladeUsed:'22mm' },
    {id:2, surgeryDate: 'Jan 4,2022', manufacturer: 'Zimmer', implant: 'Anthology', size: 'Size 3',implayType:'Extended Offset',medialBladeUsed:'22mm',lateralBladeUsed:'21mm',extendedLateralBladeUsed:'24mm' },
    {id:3, surgeryDate: 'Feb 4,2022', manufacturer: 'Smith and Nephew', implant: 'Summit', size: 'Size 9',implayType:'Standard Offset',medialBladeUsed:'27mm',lateralBladeUsed:'11mm',extendedLateralBladeUsed:'22mm' },
    {id:4, surgeryDate: 'Apr 2,2022', manufacturer: 'Stryker', implant: 'Mt Taper', size: 'Size 12',implayType:'High Offset',medialBladeUsed:'18mm',lateralBladeUsed:'18mm',extendedLateralBladeUsed:'22mm' },
    {id:5, surgeryDate: 'Apr 4,2022', manufacturer: 'DePuy', implant: 'Actis', size: 'Size 8',implayType:'High Offset',medialBladeUsed:'20mm',lateralBladeUsed:'22mm',extendedLateralBladeUsed:'22mm' },
    {id:6, surgeryDate: 'Jan 4,2022', manufacturer: 'Zimmer', implant: 'Anthology', size: 'Size 3',implayType:'Extended Offset',medialBladeUsed:'22mm',lateralBladeUsed:'21mm',extendedLateralBladeUsed:'24mm' },
    {id:7, surgeryDate: 'Feb 4,2022', manufacturer: 'Smith and Nephew', implant: 'Summit', size: 'Size 9',implayType:'Standard Offset',medialBladeUsed:'27mm',lateralBladeUsed:'11mm',extendedLateralBladeUsed:'22mm' },
    {id:8, surgeryDate: 'Apr 2,2022', manufacturer: 'Stryker', implant: 'Mt Taper', size: 'Size 12',implayType:'High Offset',medialBladeUsed:'18mm',lateralBladeUsed:'18mm',extendedLateralBladeUsed:'22mm' },

    {id:9, surgeryDate: 'Apr 4,2022', manufacturer: 'DePuy', implant: 'Actis', size: 'Size 8',implayType:'High Offset',medialBladeUsed:'20mm',lateralBladeUsed:'22mm',extendedLateralBladeUsed:'22mm' },
    {id:10, surgeryDate: 'Jan 4,2022', manufacturer: 'Zimmer', implant: 'Anthology', size: 'Size 3',implayType:'Extended Offset',medialBladeUsed:'22mm',lateralBladeUsed:'21mm',extendedLateralBladeUsed:'24mm' },
    {id:11, surgeryDate: 'Feb 4,2022', manufacturer: 'Smith and Nephew', implant: 'Summit', size: 'Size 9',implayType:'Standard Offset',medialBladeUsed:'27mm',lateralBladeUsed:'11mm',extendedLateralBladeUsed:'22mm' },
    {id:12, surgeryDate: 'Apr 2,2022', manufacturer: 'Stryker', implant: 'Mt Taper', size: 'Size 12',implayType:'High Offset',medialBladeUsed:'18mm',lateralBladeUsed:'18mm',extendedLateralBladeUsed:'22mm' },

  ];

export default function CaseList() {
  const [loading, setLoading] = useState(true);
  const [cases, setCases] = useState([]);
  useEffect(() => {
    const getAllCases = async () => {
      setLoading(true);
      try {
       // const  response = await axiosInstance.get('http://ec2-44-203-70-77.compute-1.amazonaws.com:800/api/Case');
        const response = await axiosInstance.get('api/Case/GetCases');
        if(response )
          setCases(response.data);
        else
          console.log('no response');
      //  console.log(response.data);
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    }
    getAllCases();
  }, []);

return (

  <Layout>
    {loading && <div>Loading</div>}
    {!loading && (
      <>
      <Box display="flex" sx={{mx:2, my:2,flexWrap:'wrap'}}>
      <Typography sx={{fontWeight:700,fontSize:'22px', lineHeight:'28px', flexGrow: 3 }}>Case Planning</Typography>
      <Stack direction="row" sx={{}}>
        <Button variant='contained' startIcon={<ClipboardPlusIcon/>} component={Link} to="/CaseSubmit" sx={{mr:2,fontSize:'14px',lineHeight:'20px', textTransform:'none'}}>Submit Case</Button>
        <Button variant='contained' startIcon={<LocalSee sx={{color:Colors.primaryBlue2, width:24, height:24}} />} sx={{backgroundColor:Colors.white, color:Colors.black,fontSize:'14px',lineHeight:'20px', textTransform:'none'}}>AI Implant Detection</Button>
      </Stack>
    </Box>
    <Box display="flex" sx={{justifyContent:'flex-end', mr:2, mb:1}}> <Typography sx={{fontWeight:500,fontSize:'14px', lineHeight:'20px' }}>Rep must submit data to get commission</Typography> </Box>
        <Box sx={{ height: 600,mx:2 }}>
      <DataGrid
        rows={cases}
        columns={columns}
        pageSize={8}
        rowsPerPageOptions={[8]}
        components={{ Toolbar: CustomToolbar, Footer:CustomFooter  }}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        sx={{backgroundColor:Colors.white,  '& MuiDataGrid-footerContainer':{backGroundColor:Colors.black}}}
      />
    </Box>
    </>

    )}
    
       </Layout>


);
}