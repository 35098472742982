import styled from '@emotion/styled';

import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

export const AboutImage = styled('img')(({ theme }) => ({
  height: { xs: '150px', lg: '200px' },
  padding: '10px',
  border: '2px solid green',
  [theme.breakpoints.down('md')]: {},
}));

export function KeyFeatureItem({ title, body, KeyFeatureIcon }) {
  return (
    <Box sx={{ border: '0px dotted red' }}>
      <Box sx={{ textAlign: 'center' }}>
        <Box component="img" src={KeyFeatureIcon} sx={{ height: { xs: '100px', sm: '125px', md: '150px', lg: '200px' } }} />
        {/* <AboutImage src={KeyFeatureIcon} /> */}
        <Typography sx={{ fontSize: { xs: '13px', sm: '24px' }, lineHeight: '32px', fontWeight: '700' }} gutterBottom>
          {title}
        </Typography>
        <Box sx={{ textAlign: 'left', border: '0px dotted red' }}>
          <Typography variant="body1" gutterBottom sx={{ justifySelf: 'start' }}>
            {body}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export function OsteotomeItem({ title, body, KeyFeatureIcon }) {
  return (
    <Box sx={{ border: '0px dotted red' }}>
      <Box sx={{ textAlign: 'center' }}>
        <Box component="img" src={KeyFeatureIcon} sx={{ height: { xs: '200px', sm: '175px', md: '200px', lg: '250px' } }} />
        {/* <AboutImage src={KeyFeatureIcon} /> */}
        <Box sx={{ textAlign: 'left' }}>
          <Typography sx={{ fontSize: { xs: '12px', sm: '24px' }, lineHeight: '32px', fontWeight: '700', display: 'inline' }}>{title}</Typography>

          <Typography gutterBottom sx={{ display: 'inline', fontSize: { xs: '12px', sm: '24px' } }}>
            {body}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
