import Layout from '../Layout';
import { DataGrid, GridToolbar, GridFooter, GridEditSingleSelectCell, useGridApiContext ,useGridApiRef} from '@mui/x-data-grid';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline, Button, Box, Container, Typography, Stack, Checkbox, CircularProgress, Backdrop , Autocomplete, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { CustomToolbar, CustomFooter, ClipboardPlusIcon } from '../../styles/caseList';
import { Colors } from '../../styles/theme';
import { LocalSee } from '@mui/icons-material';
import axiosInstance from '../../api/axios';
import { useEffect, useState } from 'react';
import { find } from 'lodash';
import { EditButton } from '../../styles/caseList';
import { CaseComboBox } from '../../styles/caseFormControls';
import { click } from '@testing-library/user-event/dist/click';
import DistributorSelectionModal from './DistributorSelectionModal';





export default function SalesReps() {

  const CUSTOMER ="Customer";
  const DISTRIBUTOR="Distributor";
  const OTHER ="Other";
  
  var facilityTypes = [ CUSTOMER, DISTRIBUTOR, OTHER]



  const [loading, setLoading] = useState(true);
  const [showCustomers, setShowCustomers] = useState(true);
  const [salesReps, setSalesReps] = useState([]);
  const [distributorId, setDistributorId] = useState('');
  const [distributors, setDistributors] = useState([]);
  const [clickedRow, setClickedRow] = useState('');
  const [repName, setRepName] = useState('');


  const [openOrderModal, setOpenOrderModal] = useState(false);

  const handleOpen = (row) => {
    console.log('row %o', row);
    setClickedRow(row);
    setRepName(row.firstName + ' - ' + row.lastName);
    setDistributorId(row.distributor  );
    setOpenOrderModal(true)

  };

  const saveSalesRep = async(clickedRow) => {
    const url = 'api/Case/UpsertSalesRep';
    console.log('sales rep %o', clickedRow);
    const obj = { salesRepId:clickedRow.salesRepId, firstName:clickedRow.firstName, lastName:clickedRow.lastName,  distributor: clickedRow.distributor };

    try {
      var resp = await axiosInstance.post(url, obj);
      console.log(resp.data);
     
    } catch (error) {
      console.error(error.message);
      return;
    }
   
    var updatedsalesReps = salesReps.map((item) => {
      return item.id == clickedRow.id ? { ...item, distributor: clickedRow.distributor } : item;
    });
    setSalesReps(updatedsalesReps);
  };


  const columns = [
    { field: 'action', headerName: 'Edit', sortable: false, renderCell: ({ row }) => <EditButton onClick={() => handleOpen(row)} />, disableClickEventBubbling: true },
    { field: 'salesRepId', headerName: 'Id', width: 50, editable: false },
    { field: 'firstName', headerName: 'First Name', width: 250, editable: false },
    { field: 'lastName', headerName: 'Last Name', width: 250, editable: false },
  

    {field: 'distributor', headerName: 'Distributor',   width: 300,      editable: true,    
                    valueFormatter: ({ value }) => {
                      const option = distributors?.find(i=>i.sosCustomerId == value);
                      return option ? option.hospitalName : '';
                    },

    },


   
  ];

  const getSalesReps = async () => {
    setLoading(true);
    try {
      // const  response = await axiosInstance.get('http://ec2-44-203-70-77.compute-1.amazonaws.com:800/api/Case');
      const resp = await axiosInstance.get('api/Case/GetDistributors');
      if (resp) {
        // var distributors = resp.data.map((x, index) => ({
 
        //   value: x.sosCustomerId,
        //   label:x.hospitalName,
        //   key: index,
        // }));
        var distributors=resp.data?.sort((a, b) => a.hospitalName.localeCompare(b.hospitalName));
        setDistributors(distributors);
       // console.log('distributors %o', resp.data);
        } else console.log('no response');

      const response = await axiosInstance.get('api/Case/GetSalesReps');
      if (response) {
        var salesReps = response.data.map((x, index) => ({
          ...x,
          id: x.salesRepId,
          rowId: index,
        }));
        setSalesReps(salesReps);
        console.log('salesReps %o', salesReps);
       

      } else console.log('no response');
    } catch (error) {
      console.error(error.message);
    }
    setLoading(false);
  };

  const syncSalesRep = async () => {
    setLoading(true);
    try {
      var resp = await axiosInstance.get('api/Sos/SyncSalesReps');
      var data = resp.data;
      console.log('syncSalesRep %o', data);
      getSalesReps();
    } catch (error) {
      console.error(error.message);
    }
    setLoading(false);
  };
 

  useEffect(() => {
    console.log('useEffect');
    getSalesReps();
  }, []);

  return (
    <Layout>
      <>
        <Box display="flex" sx={{ mx: 2, my: 2, flexWrap: 'wrap' }}>
          <Typography sx={{ fontWeight: 700, fontSize: '22px', lineHeight: '28px', flexGrow: 3 }}>Sales Reps Admin Console</Typography>
         
          <Button variant="contained" onClick={syncSalesRep} sx={{ mr: 2, fontSize: '14px', lineHeight: '20px', textTransform: 'none' }}>
            Sync SalesReps
          </Button>
        </Box>

        <Box sx={{ height: 700, mx: 2 }}>
          <DataGrid
            editMode="cell"
            rows={salesReps}
            columns={columns}
            pageSize={12}
            rowsPerPageOptions={[12]}
            components={{ Toolbar: CustomToolbar, Footer: CustomFooter }}
                        disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'firstName', sort: 'asc' }],
              },
            }}
            sx={{ backgroundColor: Colors.white, '& MuiDataGrid-footerContainer': { backGroundColor: Colors.black } }}
          />
        </Box>
        <DistributorSelectionModal

          distributorId={distributorId}
          distributors={distributors}
          repName={repName}
          open={openOrderModal}
          handleClose={() => setOpenOrderModal(false)}
          update={(distributorId) => {
            console.log(' update distributorId %o', distributorId);
            clickedRow.distributor = distributorId;
            saveSalesRep(clickedRow);
           
          }}
        />
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    </Layout>
  );
}
