import { BrowserRouter as Router, Routes, Route, Link, Switch } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from '../styles/theme';
import Header from './HeaderSection';
import Home from './Home/Home';
import Login from './Login';
import CaseList from './CaseList';
import CaseSubmit from './CaseSubmit';
import Dashboard from './Dashboard';
import SelectBlades from './SelectBlades';
import CaseConfirm from './CaseConfirm';
import RecommendedBlades from './RecommendedBlades';
import RegisterUser from './Admin/RegisterUser';
import SignupForm from './testFormik';
import Users from './Admin/Users';
import Facilities from './Admin/Facilities';
import ReorderList from './ReorderList';
import RegisterUserConfirmed from './RegisterUserConfirmed';
import RegisterFacility from './Admin/RegisterFacility';
import ModifyUser from './Admin/ModifyUser';
import ModifyReorder from './ModifyReorder';
import OrderNow from './OrderNow';
import AdminUsers from './AdminUsers';
import ModifyFacility from './Admin/ModifyFacility';
import Manufacturers from './Admin/Manufacturers';
import Implants from './Admin/Implants';
import AddManufacturer from './Admin/AddManufacturer';
import AddImplant from './Admin/AddImplant';
import Education from './Education';
import SalesReps from './Admin/SalesReps';
import Disclaimer from './Disclaimer'
import Layout from './Layout';
import ReactGA from 'react-ga';
import { ProtectedRoute } from "./ProtectedRoute";

ReactGA.initialize('G-MMW94XN4CZ');

function App() {
  console.log('version : %o, node env %o', process.env.REACT_APP_API_URL, process.env.NODE_ENV);
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /* Material UI Styles */ />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route path="/Login" element={<Login />}></Route>
          <Route path="/Home" element={<Home />}></Route>
          <Route path="/AAOS" element={<Home isDemo={true} />}></Route>
          <Route path="/Education" element={ <ProtectedRoute><Education /></ProtectedRoute> }></Route>
          <Route path="/Dashboard" element={ <ProtectedRoute><Dashboard /></ProtectedRoute> }></Route>
          <Route path="/CaseList" element={ <ProtectedRoute><CaseList /></ProtectedRoute> }></Route>
          <Route path="/CaseSubmit" element={ <ProtectedRoute><CaseSubmit /></ProtectedRoute> }></Route>
          <Route path="/SelectBlades" element={ <ProtectedRoute><SelectBlades /></ProtectedRoute> }></Route>
          <Route path="/CaseConfirm" element={ <ProtectedRoute><CaseConfirm /></ProtectedRoute> }></Route>
          <Route path="/RecommendedBlades" element={ <ProtectedRoute><RecommendedBlades /></ProtectedRoute> }></Route>
          <Route path="/RegisterUser"element={ <ProtectedRoute><RegisterUser /></ProtectedRoute> }></Route>
          <Route path="/RegisterFacility" element={ <ProtectedRoute><RegisterFacility /></ProtectedRoute> }></Route>
          <Route path="/RegisterUserConfirmed" element={ <ProtectedRoute><RegisterUserConfirmed /></ProtectedRoute> }></Route>
          <Route path="/ModifyUser" element={ <ProtectedRoute><ModifyUser /></ProtectedRoute> }></Route>
          <Route path="Users" element={ <ProtectedRoute><Users /></ProtectedRoute> }></Route>
          <Route path="Manufacturers" element={ <ProtectedRoute><Manufacturers /></ProtectedRoute> }></Route>
          <Route path="Implants" element={ <ProtectedRoute><Implants /></ProtectedRoute> }></Route>
          <Route path="Facilities" element={ <ProtectedRoute><Facilities /></ProtectedRoute> }></Route>
          <Route path="/ModifyFacility" element={ <ProtectedRoute><ModifyFacility /></ProtectedRoute> }></Route>
          <Route path="ReorderList" element={ <ProtectedRoute><ReorderList /></ProtectedRoute> }></Route>
          <Route path="ModifyReorder" element={ <ProtectedRoute><ModifyReorder /></ProtectedRoute> }></Route>
          <Route path="OrderNow" element={ <ProtectedRoute><OrderNow /></ProtectedRoute> }></Route>
          <Route path="/AdminUsers" element={ <ProtectedRoute><AdminUsers /></ProtectedRoute> }></Route>
          <Route path="/AddManufacturer" element={ <ProtectedRoute><AddManufacturer /></ProtectedRoute> }></Route>
          <Route path="/AddImplant" element={ <ProtectedRoute><AddImplant /></ProtectedRoute> }></Route>
          <Route path="/SalesReps" element={ <ProtectedRoute><SalesReps /></ProtectedRoute> }></Route>
          <Route path="/Disclaimer" element={<Disclaimer />}></Route>
          <Route path="*" element={<Home />}></Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
