import { Navigate } from "react-router-dom";
import AuthService from "../api/authService";

export const ProtectedRoute = ({ children }) => {
    const user = AuthService.getCurrentUser();
    if (!user) {
      // user is not authenticated
      return <Navigate to="/Login" />;
    }
    return children;
  };

