import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Box, Avatar, Typography, useMediaQuery, Menu, MenuItem, Tabs, Tab } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import SvgIcon from '@mui/material/SvgIcon';
import Logo from '../assets/ExtractionSVG1.svg';
import ExtractionSVG1 from '../assets/ExtractionSVG1.svg';
import Polygon5 from '../assets/Polygon5.svg';
import Colors from '../styles/theme';
import theme from '../styles/theme';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import AuthService from '../api/authService';
import { usePickerState } from '@mui/x-date-pickers/internals';
import { NavigationMenu, NavigationButtons } from '../styles/navigation';
import OrderModal from './Home/OrderModal';
import ReactGA from 'react-ga';
import useAnalytics from '../shared/useAnalytics';
import { TrademarkSymbol } from '../styles/symbols';

export function LogoBox() {
  return <Box component="img" src={Polygon5} sx={{ width: '100px' }} />;
}

export default function HeaderSection(props) {
  const gaEvent = useAnalytics('Header');

  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentUser, setCurrentUser] = useState(undefined);
  const [userLoggedIn, setuserLoggedIn] = useState(false);
  const [showSignIn, setShowSignIn] = useState(true);
  const [showSignUp, setShowSignUp] = useState(true);
  const [showAdmin, setShowAdmin] = useState(false);
  const [showEducationPanel, setShowEducationPanel] = useState(false);
  const [showReorderPanel, setShowReorderPanel] = useState(false);
  const [showSearchForBladePanel, setShowSearchForBladePanel] = useState(false);
  const [showOrderNow, setShowOrderNow] = useState(true);

  const [openOrderModal, setOpenOrderModal] = useState(false);

  const [value, setValue] = React.useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setuserLoggedIn(true);
      setShowAdmin(user.visibilityAdmin);
      setShowEducationPanel(user.visibilityEducationPanel);
      setShowReorderPanel(user.visibilityReorderPanel);
      setShowSearchForBladePanel(user.visibilitySearchForBladePanel);
      setCurrentUser(user);
      setShowSignIn(false);
      setShowSignUp(false);
      setShowOrderNow(false);
      // console.log(user);
    } else {
      setuserLoggedIn(false);
      setShowAdmin(false);
      setCurrentUser(undefined);
      setShowSignIn(true);
      setShowSignUp(true);
      setShowOrderNow(true);
    }
  }, []);

  const handleLogout = async (e) => {
    e.preventDefault();
    await AuthService.logout();
    console.log('logout');
    navigate('/Login');
  };

  return (
    <AppBar position="static" color="inherit" elevation={0} sx={{ height: '100px' }}>
      <Toolbar>
        <Button component={Link} to="/">
          {' '}
          <Box component="img" src={ExtractionSVG1} sx={{ width: { xs: '100px' } }} />{' '}
        </Button>

        {!matches && (
          <Box sx={{ width: '100%' }}>
            <Typography component="span" variant="h5" noWrap sx={{ ml: 2, pl: 2, flexGrow: 1, fontWeight: 500 }}>
              Watson Extraction System{TrademarkSymbol()}
            </Typography>
          </Box>
        )}

        <Box sx={{ width: '100%', textAlign: 'right' }}>
        {showOrderNow &&(<Button
            variant="contained"
            color="purple"
            component={Link}
              to="/Disclaimer"
            sx={{ textTransform: 'none', borderRadius: '100px',mr: 1 }}
          >
            <Typography noWrap> Disclaimer</Typography>
          </Button> )}
          {showOrderNow &&(<Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenOrderModal(true);
              gaEvent('ordernow', 'ordernow');
            }}
            sx={{ textTransform: 'none', borderRadius: '100px',mr: 1 }}
          >
            <Typography noWrap> Order now</Typography>
          </Button> )}

          {showSignIn && (
            <Button
              variant="contained"
              color="secondaryBlue2"
              component={Link}
              to="/Login"
              sx={{  textTransform: 'none', borderRadius: '100px', px: 4, '&:hover': { backgroundColor: Colors.secondaryBlue1 } }}
            >
              <Typography sx={{ color: Colors.primaryBlue1 }}> Login </Typography>
            </Button>
            // <Button variant="outlined" color="primary" component={Link} to="/Login">
            //   <Typography sx={{ textTransform: 'none', borderRadius: '100px' }}>Login</Typography>
            // </Button>
          )}
          {userLoggedIn && (
            <Button variant="text" color="inherit" onClick={handleLogout}>
              <Typography sx={{ color: Colors.black, textTransform: 'none' }}>Logout</Typography>
            </Button>
          )}
        </Box>
      </Toolbar>
      <OrderModal open={openOrderModal} handleClose={() => setOpenOrderModal(false)} />
      <Box>{userLoggedIn && <NavigationMenu />}</Box>
    </AppBar>
  );
}
