import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const VideoPlayer = ({ embedId, isDemo = false }) => {
  const autoplay = isDemo ? '&autoplay=1&mute=1' : '&autoplay=0';
  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
      <iframe
        src={`https://www.youtube.com/embed/${embedId}?rel=0${autoplay}&loop=1&playlist=${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        style={{ left: 0, top: 0, height: '100%', width: '100%', position: 'absolute' }}
      />
    </Box>
  );
};

VideoPlayer.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default VideoPlayer;
