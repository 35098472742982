import Layout from '../Layout';
import { DataGrid, GridToolbar, GridFooter, useGridApiRef, GridActionsCellItem } from '@mui/x-data-grid';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline, Button, Box, Container, Typography, Stack, Checkbox } from '@mui/material';

import { Link, useNavigate } from 'react-router-dom';
import { CustomToolbar, CustomFooter, ClipboardPlusIcon } from '../../styles/caseList';
import { Colors } from '../../styles/theme';
import { LocalSee } from '@mui/icons-material';
import axiosInstance from '../../api/axios';
import { useEffect, useState } from 'react';
import { find } from 'lodash';
import { EditButton, SaveButton } from '../../styles/caseList';
import { Edit, Close, Delete, Save } from '@mui/icons-material';
import { Grid as DevGrid, Table, TableHeaderRow, TableEditRow, TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import { IntegratedSorting, SortingState, EditingState } from '@devexpress/dx-react-grid';
import { Command } from '../../styles/devGrid';

export default function Implants() {
  const navigate = useNavigate();
  const apiRef = useGridApiRef();

  const showForm = (clickedRow) => {
    //alert(JSON.stringify(clickedRow));
    navigate('/ModifyUser', { state: clickedRow });
  };

  const columns = [
    { name: 'implantId', title: 'ID' },
    { name: 'implantName', title: 'Implant Name' },
    { name: 'manufacturerName', title: 'Manufacturer Name' },
    { name: 'sortNumber', title: 'SortNumber' },
  ];

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [dropDownData, setDropDownData] = useState([]);

  const [manufacturerData, setmanufacturerData] = useState([]);
  const [implantData, setImplantData] = useState([]);

  var manufacturers = {};
  useEffect(() => {
    const url = 'api/Case/GetBladeDataForDropdowns';

    axiosInstance
      .get(url)
      .then(function (innerResponse) {
        console.log('resp %o', innerResponse.data);
        setDropDownData(innerResponse.data);
        var sortedManufacturers = innerResponse.data.manufacturers.sort(function (a, b) {
          return b.sortNumber - a.sortNumber || a.manufacturerName.localeCompare(b.manufacturerName);
        });
        var manufacturers = innerResponse.data.manufacturers
          .sort(function (a, b) {
            return a.sortNumber - b.sortNumber || a.manufacturerName.localeCompare(b.manufacturerName);
          })
          .map((item) => {
            const obj = {};
            obj.id = item.manufacturerId;
            obj.label = item.manufacturerName;
            return obj;
          });

        setmanufacturerData(manufacturers);

        var implants = innerResponse.data.implants.map((x, index) => ({
          ...x,
          id: x.implantId,
          rowId: index,
          manufacturerName: manufacturers.find((m) => m.id == x.manufacturerId)?.label,
        }));
        setRows(implants);
        console.log('implants %o', implants);
        setLoading(false);
      })
      .catch(function (innerResponse) {
        console.log(innerResponse);
      });
  }, []);

  const updateApi = async (row) => {
    try {
      var userObj = row;
      delete userObj.rowId;
      delete userObj.id;
      var resp = await axiosInstance.post('api/Case/UpdateManufacturer', userObj);
      console.log(resp.data);
      // navigate('/users', { state: resp.data });
      return resp.data;
    } catch (error) {
      console.error(error.message);
    }
  };
  const getRowId = (row) => row.id;
  const changeAddedRows = (value) => {
    const initialized = value.map((row) => (Object.keys(row).length ? row : { manufacturerName: '' }));
    setAddedRows(initialized);
  };
  const commitChanges = async ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
      console.log('added rows %o', changedRows);
    }
    if (changed) {
      var rowId = Object.keys(changed)[0];
      console.log('row %o changed %o %o', rows[rowId].id, changed[3], changed[rowId]);
      changedRows = rows.map((row) => (changed[row.rowId] ? { ...row, ...changed[rowId] } : row));
      console.log('changed rows %o', changedRows);
      var updated = rows.find((r) => r.rowId == rowId);
      console.log('changed %o,  row id : %o rows %o, changed rows %o', changed, rowId, rows, updated);
      updated = { ...updated, ...changed[rowId] };
      console.log('updated row %o', updated);
      var returnvalue = await updateApi(updated);
      console.log(returnvalue);
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = rows.filter((row) => !deletedSet.has(row.id));
      console.log('deleted rows %o', deletedSet);
    }
    setRows(changedRows);
  };
  return (
    <Layout>
      {loading && <div>Loading</div>}
      {!loading && (
        <>
          <Box display="flex" sx={{ mx: 2, my: 2, flexWrap: 'wrap' }}>
            <Typography sx={{ fontWeight: 700, fontSize: '22px', lineHeight: '28px', flexGrow: 3 }}>Implants Console</Typography>
            <Button variant="contained" component={Link} to="/AddImplant" sx={{ mr: 2, fontSize: '14px', lineHeight: '20px', textTransform: 'none' }}>
              Add Implant
            </Button>
          </Box>

          <Box sx={{ mx: 2, backgroundColor: Colors.white }}>
            <DevGrid rows={rows} columns={columns}>
              <SortingState defaultSorting={[{ columnName: 'implantName', direction: 'asc' }]} />
              <EditingState
                editingRowIds={editingRowIds}
                onEditingRowIdsChange={setEditingRowIds}
                rowChanges={rowChanges}
                onRowChangesChange={setRowChanges}
                addedRows={addedRows}
                onAddedRowsChange={changeAddedRows}
                onCommitChanges={commitChanges}
              />
              <IntegratedSorting />
              <Table />
              <TableHeaderRow showSortingControls />
              <TableEditRow />
              <TableEditColumn width={250} showEditCommand showDeleteCommand commandComponent={Command} />
            </DevGrid>
          </Box>
        </>
      )}
    </Layout>
  );
}
