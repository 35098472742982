import { useState } from 'react';
import { Typography, Button, Box, Container, Paper, TextField, Autocomplete } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import theme from '../styles/theme';
import { Colors } from '../styles/theme';
import { LocalizationProvider, DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
export function RegisterTextField(props) {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{ mr: 3, pb: 2, border: '0px dashed red' }}>
      <Typography sx={{ fontSize: '16px', lineHeight: '19px', fontWeight: '400' }}> {props.label} </Typography>
      <TextField
        id={props.id}
        placeholder={props.label}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        error={props.error}
        helperText={props.helperText}
        inputRef={props.inputRef}
        InputProps={{ sx: { backgroundColor: Colors.white, '& .MuiInputBase-input': {} } }}
        sx={{ width: '100%' }}
      />
    </Box>
  );
}

export function RegisterMultiLineTextField(props) {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{ pb: 1, border: '0px dashed red' }}>
      <Typography sx={{ fontSize: '16px', lineHeight: '19px', fontWeight: '400' }}> {props.label} </Typography>
      <TextField
        id={props.id}
        placeholder={props.label}
        multiline
        rows={props.rows}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        error={props.error}
        helperText={props.helperText}
        inputRef={props.inputRef}
        InputProps={{ sx: { backgroundColor: Colors.white, '& .MuiInputBase-input': {} } }}
        sx={{ width: { xs: '100%', sm: '100%' } }}
      />
    </Box>
  );
}

export function RegisterComboBox({ label, options, inputValue, value, onChange, getOptionLabel }) {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{ mr: 3, pb: 2 }}>
      <Typography sx={{ fontSize: '11px', lineHeight: '16px', fontWeight: '500' }}> {label} </Typography>
      <Autocomplete
        disablePortal
        id="registerComboBox"
        value={value}
        inputValue={inputValue}
        onChange={onChange}
        options={options}
        getOptionLabel={getOptionLabel}
        sx={{ width: { xs: 340, sm: 210 }, backgroundColor: Colors.white }}
        renderInput={(params) => <TextField placeholder={label} {...params} />}
      />
    </Box>
  );
}
