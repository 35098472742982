import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, Formik, Field, Form, ErrorMessage, setFieldValue } from 'formik';

import { TextField, Button, Box, Typography, Grid, Stack, FormLabel, FormControl, FormControlLabel, Select, MenuItem, Checkbox } from '@mui/material';
import { Colors } from '../../styles/theme';
import { register } from '../../slices/auth';
import { clearMessage } from '../../slices/message';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Layout from '../Layout';
import { LoginLabel } from '../../styles/login';
import { RegisterTextField, RegisterMultiLineTextField } from '../../styles/registerFormControls';
import axiosInstance from '../../api/axios';
import { implantValidationSchema } from '../../validations/validationSchema';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AuthService from '../../api/authService';
import { AddedUser } from '../../styles/facility';

export default function AddImplant() {
  const [manufacturersData, setManufacturersData] = useState([]);

  const [successful, setSuccessful] = useState(false);

  const { message } = useSelector((state) => state.message);

  const navigate = useNavigate();
  const user = AuthService.getCurrentUser();
  const location = useLocation();
  const state = location.state;

  useEffect(() => {
    const getRows = async () => {
      try {
        const response = await axiosInstance.get('api/Case/GetManufacturers');
        if (response) {
          var manufacturers = response.data
            .sort(function (a, b) {
              return a.sortNumber - b.sortNumber || a.manufacturerName.localeCompare(b.manufacturerName);
            })
            .map((item) => {
              const obj = {};
              obj.id = item.manufacturerId;
              obj.label = item.manufacturerName;
              return obj;
            });
          setManufacturersData(manufacturers);
          console.log('manufacturers %o', manufacturers);
        } else console.log('no response');
      } catch (error) {
        console.error(error.message);
      }
    };
    getRows();
  }, []);

  const defaultValues = {
    implantId: state?.implantId ?? 0,
    implantName: state?.implantName ?? '',
    manufacturerId: state?.manufacturerId ?? 0,
    sortNumber: state?.sortNumber ?? 10,
  };

  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm({ mode: 'onChange', defaultValues, resolver: yupResolver(implantValidationSchema) });

  const sendData = async (values) => {
    var obj = values;
    const url = 'api/Case/AddImplant';
    try {
      var resp = await axiosInstance.post(url, obj);
      console.log(resp.data);
      return resp.data;
    } catch (error) {
      console.error(error.message);
    }
    return null;
  };
  const onSubmit = async (values) => {
    console.log('onSubmit %o', values);
    navigate('/Implants');
    // var resp = await sendData(values);
    // if (resp) navigate('/Manufacturers', { state: resp });
  };

  const onError = (errors, e) => console.log(errors);

  return (
    <Layout>
      <Box display="flex" sx={{ my: 2, border: '0px dashed blue', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', border: '0px dashed yellow', backgroundColor: Colors.white, px: { xs: 4, sm: 30 } }}>
          <Box sx={{ my: 8, display: 'flex', width: { xs: '300px', sm: '350px' }, flexDirection: 'column', alignItems: 'left', border: '0px dashed yellow' }}>
            <Typography align="left" sx={{ fontSize: '30px', lineHeight: '35px', fontWeight: '700' }}>
              Add Implant
            </Typography>
            <Box component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate sx={{ mt: 4, border: '0px dashed green' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="implantName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterTextField
                        id="implantName"
                        onChange={onChange}
                        value={value}
                        label="Implant Name"
                        error={!!errors.implantName}
                        helperText={errors.implantName && `${errors.implantName.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ mr: 3, pb: 2, border: '0px dashed red' }}>
                    <Typography sx={{ fontSize: '16px', lineHeight: '19px', fontWeight: '400' }}> Manufacturer</Typography>
                    <Controller
                      control={control}
                      name="manufacturerId"
                      defaultValue={defaultValues.reorderType}
                      render={({ field }) => (
                        <Select {...field} fullWidth>
                          {manufacturersData.map((m) => (
                            <MenuItem value={m.id}>{m.label}</MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="sortNumber"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterTextField
                        id="sortNumber"
                        onChange={onChange}
                        value={value}
                        label="Sort Number"
                        error={!!errors.sortNumber}
                        helperText={errors.sortNumber && `${errors.sortNumber.message}`}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2, mb: 3, height: '50px', textTransform: 'none' }}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}
