import { ThemeProvider } from '@emotion/react';
import { styled, alpha } from '@mui/material/styles';
import { InputBase, Button, Typography, Paper, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { Assignment, AssignmentTurnedIn } from '@mui/icons-material';
import { Colors } from './theme';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Colors.white,
  '&:hover': {
    backgroundColor: Colors.white,
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export function SearchBox() {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
    </Search>
  );
}

const IconImage = styled('img')(({ theme }) => ({
  width: '140px',
  /*padding: '10px',*/

  [theme.breakpoints.down('md')]: { width: '110px' },
}));
export function CaseButton({ label, CaseIcon, nextPage }) {
  const navigate = useNavigate();
  console.log({ nextPage });
  return (
    <Paper elevation={3} sx={{ width: { sm: '350px' }, height: { xs: '140px', sm: '300px' }, mx: { xs: 2, sm: 2 }, my: { xs: 1, sm: 1 } }} onClick={() => navigate(nextPage)}>
      <Box display="flex" alignItems="center" sx={{ pt: { xs: 2, sm: 8 }, pl: { xs: 4, sm: 0 }, flexDirection: { xs: 'row', sm: 'column' } }}>
        <IconImage src={CaseIcon} />
        <Typography sx={{ fontSize: '20px', lineHeight: '40px', fontWeight: 500, ml: { xs: 2, sm: 0 } }}>{label} </Typography>
      </Box>
    </Paper>
  );
}
