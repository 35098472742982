import { Typography, Button, Box, Container, Paper } from '@mui/material';
import { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { boxStyles } from '../../styles/customStyles';
import Polygon1 from '../../assets/Polygon1.svg';
import banner1 from '../../assets/banner1.png';
import banner2 from '../../assets/banner2.png';
import bannertools2 from '../../assets/bannertools2.png';

import bannerTools from '../../assets/banner-tools.svg';
import bannerWatsonExtraction from '../../assets/banner-watson-extraction.svg';
import { Colors } from '../../styles/theme';
import { useMediaQuery } from '@mui/material';
import theme from '../../styles/theme';

export default function BannerSection() {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <Box sx={{ position: 'relative' }}>
        <Box component="img" src={banner2} sx={{ position: 'absolute', width: '53%', zIndex: '2' }} />
        <Box component="img" src={bannertools2} sx={{ position: 'absolute', right: '0', width: '63%' }} />
        <Box sx={{ zIndex: 5, position: 'relative', pl: 2, py: 2 }}>
          <Box component="img" src={bannerWatsonExtraction} sx={{ width: '78px', zIndex: 5 }} />
          <Box sx={{}}>
            <Typography sx={{ fontWeight: 400, fontSize: '12px', lineHeight: '16px', color: '#FFFFFF', zIndex: 5 }}> The 1-2 punch</Typography>
            <Typography sx={{ fontWeight: 400, fontSize: '12px', lineHeight: '16px', color: '#FFFFFF' }}> for Well Fixed Stems</Typography>
          </Box>
          <Box sx={{ width: '150px', mt: 2 }}>
            <Typography sx={{ fontWeight: 400, fontSize: '12px', lineHeight: '16px', color: '#FFFFFF' }}>
              {' '}
              Specifically developed osteotomes using "Bone Conserving Technology" to facilitate the removal of non-cemented femoral stems
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
