import Layout from './Layout';
import { DataGrid, GridToolbar, GridFooter, GridComparatorFn } from '@mui/x-data-grid';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline, Button, Box, Container, Typography, Stack, Checkbox, CircularProgress, Backdrop } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { CustomToolbar, CustomFooter, ClipboardPlusIcon } from '../styles/caseList';
import { Colors } from '../styles/theme';
import { LocalSee } from '@mui/icons-material';
import { EditButton, SyncButton,RetryButton } from '../styles/caseList';
import axiosInstance from '../api/axios';
import { useEffect, useState } from 'react';
import { find } from 'lodash';
import dayjs from 'dayjs';
import AuthService from '../api/authService';

export default function ReorderList() {
  const user = AuthService.getCurrentUser();
  const isAdmin = user.userTypeId == 2;
  function getFormattedDate(params: GridValueGetterParams) {
    let formattedDate = dayjs(params.value).format('MM/DD/YYYY');

    return formattedDate == '01/01/1901' ? '' : formattedDate;
  }

  const navigate = useNavigate();
  const showForm = (clickedRow) => {
    navigate('/ModifyReorder', { state: clickedRow });
  };
  const dayInMonthComparator: GridComparatorFn<Date> = (v1, v2) => {
    console.log('v1: %o v2:%o val %o', v1, v2, new Date(v1) - new Date(v2));
    return new Date(v1) - new Date(v2);
  };

  const [sortModel, setSortModel] = useState([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  const columns = [
    {
      field: 'action',
      headerName: 'Edit',
      sortable: false,
      hide: !isAdmin,
      renderCell: ({ row }) => <EditButton onClick={() => showForm(row)} />,
      disableClickEventBubbling: true,
    },
    {
      field: 'sosOrderStatus',
      headerName: 'Rep Order Status ',
      sortable: true,
      hide: false,
      width: 150,
      renderCell: (params) => {
        if (isAdmin && params.row.sosOrderStatus == 'New') return <SyncButton onClick={() => onSosPush(params.row)} />;
         if (isAdmin && params.row.sosOrderStatus == 'Error') return <RetryButton onClick={() => onSosPush(params.row)} />;
        return <div>{params.row.sosOrderStatus} </div>;
      },
      disableClickEventBubbling: true,
    },
    { field: 'sosOrderNum', headerName: 'SOS Order Number', width: 150, editable: false },
    { field: 'id', headerName: 'Id', width: 300, editable: false, hide: true },
    { field: 'hospitalName', headerName: 'Hospital Name', width: 300, editable: false },
    { field: 'surgeon', headerName: 'Surgeon', width: 300, editable: false },
    { field: 'surgeryDate', headerName: 'Surgery Date', width: 200, type: 'datetime', editable: false, valueFormatter: getFormattedDate },
  ];

  const rows = [{ id: 1, username: 'Apr 4,2022', firstName: 'DePuy', lastName: 'Actis', email: 'Size 8', phone: 'High Offset', address1: '20mm' }];

  const [loading, setLoading] = useState(true);
  const [cases, setCases] = useState([]);
  console.log('outside');
  useEffect(() => {
    const getAllCases = async () => {
      console.log('getallcases');
      setLoading(true);
      try {
        // const  response = await axiosInstance.get('http://ec2-44-203-70-77.compute-1.amazonaws.com:800/api/Case');
        const response = await axiosInstance.get('api/Case/GetCasesByUserId', { params: { userId: user.userId } });
        if (response) {
          var cases = response.data.map((x, index) => ({
            ...x,
            //  id: x.id,
            rowId: index,
          }));
          setCases(cases);
        } else console.log('no response');
        console.log(cases);
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };
    getAllCases();
  }, []);

  const onSosPush = async (row) => {
    try {
      const { rowId, ...reorderObj } = row;

      console.log('modified row %o', reorderObj);
      setLoading(true);
      var resp = await axiosInstance.post('api/Case/PushSos', reorderObj);
      var data = resp.data;
      var updatedCases = cases.map((item) => {
        return item.id == row.id ? { ...item, sosOrderStatus: data?.sosOrderStatus, sosOrderNum: data?.sosOrderNum } : item;
      });
      setCases(updatedCases);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <Layout>
      <>
        <Box sx={{ height: 700, mx: 2, mt:2 }}>
          <DataGrid
            editMode="row"
            rows={cases}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            components={{ Toolbar: CustomToolbar, Footer: CustomFooter }}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            sx={{ backgroundColor: Colors.white, '& MuiDataGrid-footerContainer': { backGroundColor: Colors.black } }}
          />
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    </Layout>
  );
}
