import { Typography, Button, Box, Container, Paper } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import theme from '../styles/theme'

export  function LoginLabel({label}){
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    return(
        
        <>
             <Typography sx={{fontSize:'16px', lineHeight:'19px',fontWeight:'400'  }}> {label} </Typography>

        </>

    );
                    
};