import { useState } from 'react';
import { Typography, Button, Box, Container, Paper, TextField, Autocomplete } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import theme from '../styles/theme';
import { Colors } from '../styles/theme';
import { LocalizationProvider, DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
export function CaseTextField({ label, value, type, onChange, onBlur, optional = false }) {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{ mr: 3, pb: 2 }}>
      <Typography sx={{ fontSize: '11px', lineHeight: '16px', fontWeight: '500' }}> {label + (optional ? ' [Optional]' : '')} </Typography>
      <TextField
        id="surgeon"
        defaultValue=""
        placeholder={label}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        InputProps={{ sx: { backgroundColor: Colors.white, width: { xs: 340, sm: 210 }, '& .MuiInputBase-input': {} } }}
      />
    </Box>
  );
}

export function CaseComboBox({ label, options, inputValue, value, onChange, getOptionLabel, optional = false }) {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{ mr: 3, pb: 2, width: '100%' }}>
      <Typography sx={{ fontSize: '11px', lineHeight: '16px', fontWeight: '500' }}> {label + (optional ? ' [Optional]' : '')} </Typography>
      <Autocomplete
        disablePortal
        id="caseComboBox"
        value={value}
        inputValue={inputValue}
        onChange={onChange}
        options={options}
        getOptionLabel={getOptionLabel}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Box
                sx={{
                  fontSize: '14px',
                }}
              >
                {option.label}
              </Box>
            </li>
          );
        }}
        sx={{ minWidth: { xs: 340 }, backgroundColor: Colors.white, '& .MuiAutocomplete-listbox  .MuiAutocomplete-option': { color: 'red' } }}
        renderInput={(params) => <TextField placeholder={label} {...params} sx={{ color: Colors.primaryBlue1 }} />}
      />
    </Box>
  );
}

export function CaseDatePickerDesktop({ label, date, onChange }) {
  const [value, setValue] = useState(dayjs(date));
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  console.log('datepicker : %o', date);
  return (
    <Box sx={{ mr: 3, pb: 2 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography sx={{ fontSize: '11px', lineHeight: '16px', fontWeight: '500' }}> {label} </Typography>
        <DesktopDatePicker
          value={value}
          minDate={dayjs('2017-01-01')}
          onChange={(newValue) => {
            console.log('datepicker onChange: %o', newValue);
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
          InputProps={{ sx: { backgroundColor: Colors.white, width: { xs: 340, sm: 210 } } }}
        />
      </LocalizationProvider>
    </Box>
  );
}

export function CustomDatePicker({ value, onChange, label, id }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Typography> {label}</Typography>
      <DesktopDatePicker
        id={id}
        value={value}
        minDate={dayjs('2017-01-01')}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} />}
        InputProps={{ sx: { backgroundColor: Colors.white, width: { xs: 340, sm: 210 } } }}
      />
    </LocalizationProvider>
  );
}
