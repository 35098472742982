import * as React from 'react';

import Button from '@mui/material/Button';

import {TextField, InputLabel, InputBase } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Layout from './Layout';
import { useMediaQuery } from "@mui/material";
import theme from '../styles/theme'
import {Colors} from '../styles/theme'
import { useLocation } from 'react-router-dom';

  export default function RegisterUserConfirmed() {
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    const location = useLocation();
    console.log(location.state);
    return (
    <Layout>
        <Box  display="flex" flexDirection="column" sx={{ my:10, cursor:'pointer' }}>
            <Box sx={{textAlign:'center', my:2, mx:4}}>
                <Typography sx={{fontSize:'30px', lineHeight:'36px',fontWeight:700, mb:6}}>User Registration Confirmation</Typography>

               
                <Typography sx={{fontSize:"22px",lineHeight:"28px", fontWeight:"500", mb:2}}>userid: "{location.state.userName}" successfully registered </Typography>
             
                <Typography sx={{fontSize:"22px",lineHeight:"28px", fontWeight:"500", mb:2}}> Once approved an email will be sent to "{location.state.email}" </Typography>
               
                <Button variant="contained" color="primary"  component={Link} to="/Landing"   sx={{ mt:5, textTransform:'none', borderRadius: 100}} >
                    <Typography noWrap> OK </Typography> 
                </Button> 

            </Box>
            


        </Box>
    </Layout>
    );
  }

 