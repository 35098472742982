import { useState } from 'react';

import Button from '@mui/material/Button';

import { TextField, InputLabel, InputBase } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Layout from './Layout';
import { useMediaQuery } from '@mui/material';
import theme from '../styles/theme';
import { Colors } from '../styles/theme';
import { LoginLabel } from '../styles/login';
import AuthService from '../api/authService';

export default function Disclaimer() {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {

      var resp = await AuthService.login(username, password);
      console.log(resp);
      if (resp.isApprovedForLogin == true) {
        navigate('/Dashboard', { state: resp.data });
      } else {
        var errorMsg = resp.userName ? 'User Is Not Approved For Login' : 'Invalid Login Credentials';
        setError(errorMsg);
        //alert(errorMsg);
      }

      //navigate('/RegisterUserConfirmed', {state: resp.data});
    } catch (error) {
      console.error(error.message);
    }

  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };
  return (
    <Layout>
      <Box display="flex" sx={{ my: 2, border: '0px dashed blue', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', border: '0px dashed yellow', backgroundColor: Colors.white, px: { xs: 4, sm: 4 } }}>
          <Box sx={{ textAlign: 'center', py: 2 }}>
            <Typography>MEDICAL DEVICE WEBSITE DISCLAIMER / TERMS OF USE</Typography>
          </Box>
          <Box sx={{ textAlign: 'center', pb: 2 }}>
            <Typography sx={{fontWeight: 700}}>This website does not provide Medical Advice.</Typography>
          </Box>
          <Box sx={{ textAlign: 'left', pb: 2 }}>
            <Typography>The information, including but not limited to, text, graphics, images, and other material contained on this website are for general product information purposes only unless otherwise stated.  No material on this site is intended to be a substitute for the experience and knowledge of a licensed surgeon trained in revision hip surgery.
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'left', pb: 2 }}>
            <Typography>
              This information is provided by Simplex Designs, LLC and while we endeavor to keep this information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. The content of this website is subject to change without notice.
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'left', pb: 2 }}>
            <Typography>
              In no event will Simplex Designs, LLC be liable for any loss or damage including without limitation, indirect or consequential loss or damage in connection with the use of this website.
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'left', pb: 4 }}>
            <Typography>
              This website may link to other websites which are not under the control of Simplex Designs, LLC. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
            </Typography>
          </Box>



        </Box>
      </Box>
    </Layout>
  );
}
