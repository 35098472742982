import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, GridFooter } from '@mui/x-data-grid';
import ClipboardPlus from '../assets/ClipboardPlus.svg';
import { Box, Button } from '@mui/material';
import { Colors } from './theme';
import { Edit, Save, AddCircle, RemoveCircle, Sync, CloudUpload } from '@mui/icons-material';

export function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* <GridToolbarColumnsButton /> */}
      <GridToolbarFilterButton />
      {/* <GridToolbarDensitySelector /> */}
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export function CustomFooter() {
  return <GridFooter sx={{ backgroundColor: Colors.lightBlue1, px: 2 }} />;
}

export function ClipboardPlusIcon() {
  return <Box component="img" src={ClipboardPlus} sx={{}} />;
}

export function EditButton(props) {
  return (
    <Button variant="outlined" endIcon={<Edit />} onClick={props.onClick} sx={{ border: 'solid 1px #1972D6' }}>
      {' '}
      Edit{' '}
    </Button>
  );
}

export function SaveButton(props) {
  return (
    <Button variant="outlined" endIcon={<Save />} onClick={props.onClick} sx={{ border: 'solid 1px #1972D6' }}>
      {' '}
      Save{' '}
    </Button>
  );
}

export function SyncButton(props) {
  return (
    <Button variant="text" endIcon={<Sync />} onClick={props.onClick} sx={{ border: 'solid 1px #1972D6' }}>
      Push
    </Button>
  );
}

export function RetryButton(props) {
  return (
    <Button variant="text" endIcon={<Sync />} onClick={props.onClick} sx={{ border: 'solid 1px #1972D6' }}>
      Retry
    </Button>
  );
}

export function AddButton(props) {
  return <Button variant="outlined" startIcon={<AddCircle />} onClick={props.onClick} sx={{ border: 'solid 0px #1972D6', width: '10px' }}></Button>;
}

export function RemoveButton(props) {
  return <Button variant="text" endIcon={<RemoveCircle />} onClick={props.onClick} sx={{ border: 'solid 0px #1972D6' }}></Button>;
}
