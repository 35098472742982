import { Typography, Button, Box, Container, Paper } from '@mui/material';
import { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { boxStyles } from '../../styles/customStyles';
import Polygon1 from '../../assets/Polygon1.svg';
import banner1 from '../../assets/banner1.png';
import banner2 from '../../assets/banner2.png';
import bannertools2 from '../../assets/bannertools2.png';

import bannerTools from '../../assets/banner-tools.svg';
import bannerWatsonExtraction from '../../assets/banner-watson-extraction.svg';
import { Colors } from '../../styles/theme';
import { useMediaQuery } from '@mui/material';
import theme from '../../styles/theme';
import OrderModal from './OrderModal';
import ReactGA from 'react-ga';
import useAnalytics from '../../shared/useAnalytics';

export default function BannerSection() {
  const gaEvent = useAnalytics('Banner');
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [openOrderModal, setOpenOrderModal] = useState(false);
  return (
    <Box>
      <Box sx={{ position: 'relative', mb: 4 }}>
        <Box component="img" src={banner2} sx={{ position: 'absolute', width: { sm: '45%', md: '54%' }, zIndex: '2' }} />
        <Box component="img" src={bannertools2} sx={{ position: 'absolute', right: '0', width: { sm: '54%', md: '64%' } }} />
        <Box sx={{ zIndex: 5, position: 'relative', pl: 6, py: { sm: 4, md: 12 } }}>
          <Box component="img" src={bannerWatsonExtraction} sx={{ width: { sm: '100px', md: '292px' }, zIndex: 5 }} />
          <Box sx={{ mt: 4 }}>
            <Typography sx={{ fontWeight: 700, fontSize: { sm: '24px', md: '48px' }, lineHeight: { sm: '24px', md: '48px' }, color: '#FFFFFF', zIndex: 5 }}>
              {' '}
              The 1-2 punch
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: { sm: '24px', md: '48px' }, color: '#FFFFFF' }}> for Well Fixed Stems</Typography>
          </Box>
          <Box sx={{ mt: 4, width: '45%' }}>
            <Typography sx={{ fontWeight: 700, fontSize: { sm: '16px', md: '24px' }, color: '#FFFFFF' }}>
              {' '}
              Specifically developed osteotomes using "Bone Conserving Technology" to facilitate the removal of non-cemented femoral stems
            </Typography>
          </Box>

          <Box sx={{ mt: 4, mb: 2 }}>
            <Button
              variant="contained"
              color="secondaryBlue2"
              onClick={() => {
                setOpenOrderModal(true);
                gaEvent('ordernow', 'ordernow');
              }}
              sx={{ px: 2, py: 1, textTransform: 'none', borderRadius: '100px', height: '40px', width: '144px', '&:hover': { backgroundColor: Colors.secondaryBlue1 } }}
            >
              <Typography sx={{ color: Colors.primaryBlue1, fontWeight: 500, fontSize: '20px', lineHeight: '20px' }}> Order Now </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <OrderModal open={openOrderModal} handleClose={() => setOpenOrderModal(false)} />
    </Box>
  );
}
