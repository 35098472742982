import { useState } from 'react';

import Button from '@mui/material/Button';

import { TextField, InputLabel, InputBase } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Layout from './Layout';
import { useMediaQuery } from '@mui/material';
import theme from '../styles/theme';
import { Colors } from '../styles/theme';
import { LoginLabel } from '../styles/login';
import AuthService from '../api/authService';

export default function Login() {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
     
      var resp = await AuthService.login(username, password);
      console.log(resp);
      if (resp.isApprovedForLogin == true) {
        navigate('/Dashboard', { state: resp.data });
      } else {
        var errorMsg = resp.userName ? 'User Is Not Approved For Login' : 'Invalid Login Credentials';
        setError(errorMsg);
        //alert(errorMsg);
      }

      //navigate('/RegisterUserConfirmed', {state: resp.data});
    } catch (error) {
      console.error(error.message);
    }

  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };
  return (
    <Layout>
      <Box display="flex" sx={{ my: 10, border: '0px dashed blue', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', border: '0px dashed yellow', backgroundColor: Colors.white, px: { xs: 4, sm: 30 } }}>
          <Box sx={{ my: 8, display: 'flex', width: { xs: '300px', sm: '350px' }, flexDirection: 'column', alignItems: 'left' }}>
            <LoginLabel label="Welcome back" />
            <Typography align="left" sx={{ fontSize: '30px', lineHeight: '35px', fontWeight: '700' }}>
              {' '}
              Login to your account{' '}
            </Typography>
            <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 4 }}>
              <LoginLabel label="Username" />
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label=""
                name="username"
                autoComplete="username"
                value={username}
                onChange={onChangeUsername}
                autoFocus
                sx={{ 'label + &': { mt: 2 } }}
              />
              <LoginLabel label="Password" />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label=""
                type="password"
                id="password"
                value={password}
                onChange={onChangePassword}
                autoComplete="current-password"
              />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />

                <Link href="#" variant="outline" underline="none">
                  Forgot password?
                </Link>
              </Box>
              {error ? <InputLabel sx={{ color: Colors.danger }}>{error}</InputLabel> : null}
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 3, height: '50px', textTransform: 'none' }}>
                Login now
              </Button>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography>Don't have an account? </Typography>
                <Link variant="outlined" underline="none" href="/RegisterUser">
                  {' Join today!'}
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}
