import { Box, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Colors } from '../../styles/theme';
import { VideoItem, VideoImage } from '../../styles/video';
import video3 from '../../assets/video3.svg';
import playbutton from '../../assets/Playbutton.svg';
import video2 from '../../assets/video2.png';
import VideoPlayer from '../../styles/VideoPlayer';
import { TrademarkSymbol } from '../../styles/symbols';

export default function VideoSection(props) {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Box>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: { xs: '24px', sm: '48px' },
            lineHeight: '30px',
            display: { sm: 'inline' },
          }}
        >
          {' '}
          Introducing
        </Typography>{' '}
        <Typography sx={{ fontWeight: 400, fontSize: { xs: '24px', sm: '48px' }, lineHeight: '30px', pl: 1, display: { sm: 'inline' } }}> the Watson Extraction System{TrademarkSymbol()}</Typography>
      </Box>

      <Box sx={{ height: { xs: '200px', sm: '452px' }, width: { xs: '90%', sm: '80%', md: '800px' }, mt: 5, mx: 'auto' }}>
        <VideoPlayer embedId="8kQ-bhGjIm0" isDemo={props.isDemo} />
      </Box>
    </Box>
  );
}
