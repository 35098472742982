import { Typography, Button, Box, Container, Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { boxStyles } from '../../styles/customStyles';
import taperedWedge from '../../assets/home/taperedWedge.png';
import fitfill from '../../assets/home/fitfill.png';

import { Colors } from '../../styles/theme';
import { useMediaQuery } from '@mui/material';
import theme from '../../styles/theme';
import { TrademarkSymbol } from '../../styles/symbols';

export function Stems() {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 4fr 1fr', mx: 10, my: 4 }}>
      <Box sx={{ gridRow: '1/2' }}>
        <Box component="img" src={taperedWedge} sx={{}} />
      </Box>

      <Box sx={{ mx: -3, mt: 10 }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography sx={{ fontWeight: 700, fontSize: '36px', lineHeight: '36px', py: 1 }}> The</Typography>
          <Typography sx={{ fontWeight: 700, fontSize: '36px', lineHeight: '36px', py: 1 }}> Watson Extraction System{TrademarkSymbol()}</Typography>
          <Typography sx={{ fontWeight: 400, fontSize: '24px', lineHeight: '36px', py: 1 }}> assists in the removal of</Typography>
        </Box>
        <Box sx={{ textAlign: 'left' }}>
          <Typography sx={{ fontWeight: 700, fontSize: '48px', lineHeight: '48px', py: 5 }}> Tapered Wedge...</Typography>
        </Box>
        <Box sx={{ textAlign: 'right', pt: 2 }}>
          <Typography sx={{ fontWeight: 700, fontSize: '48px', lineHeight: '30px', display: 'inline' }}> and</Typography>
          <Typography sx={{ fontWeight: 700, fontSize: '48px', lineHeight: '30px', display: 'inline' }}> Fit & Fill</Typography>
          <Typography sx={{ fontWeight: 400, fontSize: '36px', lineHeight: '30px', display: 'inline' }}> stems</Typography>
        </Box>
      </Box>
      <Box sx={{}}>
        <Box component="img" src={fitfill} sx={{}} />
      </Box>
    </Box>
  );
}

export function StemsMobile() {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{ display: 'grid', pt: 2, height: '200px', gridTemplateColumns: '1fr 5fr 1fr' }}>
      <Box sx={{ gridRow: '1/2' }}>
        <Box component="img" src={taperedWedge} sx={{ height: '200px' }} />
      </Box>

      <Box sx={{}}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography sx={{ fontWeight: 700, fontSize: '16px' }}> The</Typography>
          <Typography sx={{ fontWeight: 700, fontSize: '16px' }}> Watson Extraction System{TrademarkSymbol()}</Typography>
          <Typography sx={{ fontWeight: 400, fontSize: '16px' }}> assists in the removal of</Typography>
        </Box>
        <Box sx={{ textAlign: 'left', pt: 2 }}>
          <Typography sx={{ fontWeight: 700, fontSize: '16px' }}> Tapered Wedge...</Typography>
        </Box>
        <Box sx={{ textAlign: 'right', pt: 2 }}>
          <Typography sx={{ fontWeight: 700, fontSize: '16px', lineHeight: '30px', display: 'inline' }}> and Fit & Fill</Typography>
          <Typography sx={{ fontWeight: 400, fontSize: '16px', lineHeight: '30px', display: 'inline' }}> stems</Typography>
        </Box>
      </Box>
      <Box sx={{}}>
        <Box component="img" src={fitfill} sx={{ height: '200px' }} />
      </Box>
    </Box>
  );
}

export function StemsMobile1() {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: 'grid', height: '200px' }}>
        <Box sx={{ gridRow: '1/2' }}>
          <Box component="img" src={taperedWedge} sx={{ height: '200px' }} />
        </Box>

        <Box sx={{ gridRow: '1/2' }}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography sx={{ fontWeight: 700, fontSize: '24px', lineHeight: '36px' }}> The</Typography>
            <Typography sx={{ fontWeight: 700, fontSize: '24px', lineHeight: '36px' }}> Watson Extraction System{TrademarkSymbol()}</Typography>
            <Typography sx={{ fontWeight: 400, fontSize: '24px', lineHeight: '36px' }}> assists in the removal of</Typography>
          </Box>
          <Box sx={{ textAlign: 'left', py: 2 }}>
            <Typography sx={{ fontWeight: 700, fontSize: '24px', lineHeight: '36px' }}> Tapered Wedge...</Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'grid', height: '200px', mt: -12 }}>
        <Box sx={{ gridRow: '1/2' }}>
          <Box sx={{ textAlign: 'center', mt: 12 }}>
            <Typography sx={{ fontWeight: 700, fontSize: '24px', lineHeight: '30px', display: 'inline' }}> and</Typography>
            <Typography sx={{ fontWeight: 700, fontSize: '24px', lineHeight: '30px', display: 'inline' }}> Fit & Fill</Typography>
            <Typography sx={{ fontWeight: 400, fontSize: '24px', lineHeight: '30px', display: 'inline' }}> stems</Typography>
          </Box>
        </Box>

        <Box sx={{ gridRow: '1/2', textAlign: 'right' }}>
          <Box component="img" src={fitfill} sx={{ height: '200px' }} />
        </Box>
      </Box>
    </Box>
  );
}
