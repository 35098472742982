import { Button, Link, Tabs, Tab, useMediaQuery, Drawer, IconButton, Typography, Divider, ButtonGroup, Stack, Box, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Colors } from './theme';
import MessageUsModal from '../components/Home/MessageUsModal';

const LinkButton = (props) => {
  const location = useLocation();
  return props.href == location.pathname ? (
    <Link
      underline="none"
      variant="button"
      component="button"
      href={props.href}
      onClick={props.onClick}
      sx={{ textTransform: 'none', px: 3, py: 1, backgroundColor: Colors.primaryBlue2, color: Colors.white, textAlign: 'left' }}
    >
      {props.label}
    </Link>
  ) : (
    <Link
      underline="none"
      variant="button"
      href={props.href}
      onClick={props.onClick}
      sx={{ px: 3, py: 1, textTransform: 'none', '&:hover': { backgroundColor: Colors.primaryBlue1, color: Colors.white } }}
    >
      {props.label}
    </Link>
  );
};

export function NavigationButtons(props) {
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openOrderModal, setOpenOrderModal] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  var isManageAccount = ['/users', '/facilities'].includes(location.pathname);
  return (
    <Box sx={{ width: '100%', backgroundColor: Colors.secondaryBlue1 }}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        divider={<Divider orientation="vertical" variant="middle" flexItem color={Colors.primaryBlue1} sx={{ mx: -1 }} />}
        sx={{ backgroundColor: Colors.secondaryBlue1 }}
      >
        <LinkButton label="Dashboard" href="/Dashboard" />
        <LinkButton label="Size Selector" href="/SelectBlades" />
        <LinkButton label="Place Order" href="/CaseSubmit" />
        <LinkButton label="Order Status" href="/ReorderList" />
        <LinkButton label="Product Info" href="/Education" />
        <LinkButton label="Contact Us" href="#" onClick={() => setOpenOrderModal(true)} />

        <Box sx={{ cursor: 'pointer' }}>
          {isManageAccount ? (
            <Box onClick={handleClick} sx={{ textTransform: 'none', px: 2, py: 1, backgroundColor: Colors.primaryBlue2, color: Colors.white, textAlign: 'left' }}>
              Admin Console
            </Box>
          ) : (
            <Box
              onClick={handleClick}
              sx={{ textTransform: 'none', px: 3, py: 1, textAlign: 'left', color: Colors.primaryBlue2, '&:hover': { backgroundColor: Colors.primaryBlue1, color: Colors.white } }}
            >
              Admin Console
            </Box>
          )}

          <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ sx: { pb: 0 } }}>
            <Stack orientation="vertical">
              <LinkButton label="Users" href="/users" />
              <LinkButton label="Facilities" href="/facilities" />
              <LinkButton label="SalesReps" href="/salesReps" />
              <LinkButton label="Manufacturerers" href="/manufacturers" />
              <LinkButton label="Implants" href="/implants" />
              <LinkButton label="Implant Sizes" href="#" />
            </Stack>
          </Menu>
        </Box>
      </Stack>
      <MessageUsModal open={openOrderModal} handleClose={() => setOpenOrderModal(false)} />
    </Box>
  );
}

export function NavigationDrawer(props) {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <IconButton edge="start" onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={openDrawer} onClose={() => setOpenDrawer(false)} sx={{ width: '100%' }}>
        <NavigationButtons orientation="vertical" onClick={() => setOpenDrawer(false)} />
      </Drawer>
    </>
  );
}

export function NavigationMenu(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return <>{isMobile ? <NavigationDrawer /> : <NavigationButtons />}</>;
}

export function NavigationLink(props) {
  return (
    <Link
      component="button"
      variant="body2"
      onClick={() => {
        console.info("I'm a button.");
      }}
    >
      {props.label}
    </Link>
  );
}

export function NavigationTabs(props) {
  const [value, setValue] = useState();
  const location = useLocation();
  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
    //props?.onClick();
  };

  return (
    <Tabs value={value} onChange={handleChange} orientation={props.orientation} sx={{ width: '100%', bgcolor: Colors.secondaryBlue1 }}>
      <Tab value="/Landing" label="Dashboard" component={Link} href="/Dashboard" />
      <Tab value="/SelectBlades" label="Select Blades" component={Link} href="/SelectBlades" />
      <Tab value="/CaseSubmit" label="Submit Case" component={Link} href="/CaseSubmit" />
      <Tab value="/" label="Manage Account" component={Link} href="/CaseSubmit" />
    </Tabs>
  );
}
