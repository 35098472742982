import { useState, useEffect } from 'react';

import Button from '@mui/material/Button';

import { FormLabel, RadioGroup, FormControl, Radio, TextField, List, ListItem } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import Layout from './Layout';
import { useMediaQuery, Autocomplete } from '@mui/material';
import theme from '../styles/theme';
import { Colors } from '../styles/theme';
import { LoginLabel } from '../styles/login';
import { ArrowBack } from '@mui/icons-material';
import { CaseTextField, CaseComboBox, CaseDatePickerDesktop } from '../styles/caseFormControls';
import { useAxiosPost } from '../api/api';
// import {  implantTypes, implantSizes, bladeSizes } from '../api/data';
import { useForm } from 'react-hook-form';
import axiosInstance from '../api/axios';
import { LocalizationProvider, DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useDebugValue } from 'react';

export default function SelectBlades() {
  const [surgeryDate, setSurgeryDate] = useState(dayjs());
  const [surgeon, setSurgeon] = useState('');
  const [extractedManufacturer, setExtractedManufacturer] = useState('');
  const [extractedImplant, setExtractedImplant] = useState('');
  const [extractedSize, setExtractedSize] = useState('');
  const [extractedImplantType, setExtractedImplantType] = useState('');
  const [lateralPartNumber, setLateralPartNumber] = useState('');
  const [medialPartNumber, setMedialPartNumber] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [implant, setImplant] = useState('');
  const [size, setSize] = useState('');
  const [implantType, setImplantType] = useState('');
  const [submittedBy, setSubmittedBy] = useState('');

  const [lateralWidth, setLateralWidth] = useState('');
  const [medialWidth, setMedialWidth] = useState('');

  const [bladeSelectOption, setBladeSelectOption] = useState('implant');
  const [dropDownData, setDropDownData] = useState([]);

  const [manufacturerData, setmanufacturerData] = useState([]);
  const [implantData, setImplantData] = useState([]);
  const [implantTypeData, setImplantTypeData] = useState([]);
  const [sizeData, setSizeData] = useState([]);

  var manufacturers = {};
  useEffect(() => {
    const url = 'api/Case/GetBladeDataForDropdowns';

    axiosInstance
      .get(url)
      .then(function (innerResponse) {
        // setManufacturer(innerResponse.data.manufacturers);
        setDropDownData(innerResponse.data);
        // console.log(dropDownData);
        var sortedManufacturers = innerResponse.data.manufacturers.sort(function (a, b) {
          return b.sortNumber - a.sortNumber || a.manufacturerName.localeCompare(b.manufacturerName);
        });
        var manufacturers = innerResponse.data.manufacturers
          .sort(function (a, b) {
            return  a.sortNumber - b.sortNumber || a.manufacturerName.localeCompare(b.manufacturerName);
          })
          .map((item) => {
            const obj = {};
            obj.id = item.manufacturerId;
            obj.label = item.manufacturerName;
            return obj;
          });

        setmanufacturerData(manufacturers);
        //    console.log(manufacturerData);
      })
      .catch(function (innerResponse) {
        console.log(innerResponse);
      });
  }, []);
  const onSelectManufacturerOption = (newValue) => {
    if (newValue != null) {
      clearDropdowns();
      setManufacturer(newValue);
      var filteredImplants = dropDownData.implants.filter((i) => i.manufacturerId === newValue.id);

      if (filteredImplants) {
        var implants = filteredImplants
          .sort(function (a, b) {
            return a.implantName.localeCompare(b.implantName);
          })
          .map((item) => {
            const obj = {};
            obj.id = item.implantId;
            obj.label = item.implantName;
            return obj;
          });
        setImplantData(implants);
        //  console.log('implant data: %o' , implantData);
      }
    } else {
      console.log('no match for implants');
      setImplantData([]);
    }
  };
  const clearDropdowns = () => {
    setImplant('');
    setImplantType('');
    setSize('');
    setLateralPartNumber('');
    setMedialPartNumber('');
    setImplantData([]);
    setImplantTypeData([]);
    setSizeData([]);
  };
  const onSelectImplantOption = (newValue) => {
    if (newValue != null) {
      setImplant(newValue);
      setImplantType('');
      setSize('');
      setLateralPartNumber('');
      setMedialPartNumber('');
      setImplantTypeData([]);
      setSizeData([]);
      var filteredImplantTypes = dropDownData.implantTypes;
      if (filteredImplantTypes) {
        var implantTypes = filteredImplantTypes.map((item) => {
          const obj = {};
          obj.id = item.implantTypeId;
          obj.label = item.implantTypeName;
          return obj;
        });
        setImplantTypeData(implantTypes);
        console.log(implantTypeData);
      }

      var filteredImplantSizes = dropDownData.implantSizes.filter((i) => i.implantId === newValue.id);
      if (filteredImplantSizes) {
        var implantSizes = filteredImplantSizes
          .sort(function (a, b) {
            return a.implantSizeId - b.implantSizeId || a.implantSizeId.localeCompare(b.implantSizeId);
          })
          .map((item) => {
            const obj = {};
            obj.id = item.implantSizeId;
            obj.label = item.implantSizeName;
            return obj;
          });
        setSizeData(implantSizes);
        // console.log('sizeData: %o' , sizeData);
      }
    } else {
      console.log('no match for implants');
      setImplantData([]);
    }
  };

  const onSelectSizeOption = (newValue) => {
    if (newValue != null) {
      setSize(newValue);
      setLateralPartNumber('');
      setMedialPartNumber('');
      var bladeSelected = dropDownData.blades.find((i) => i.implantId == implant.id && i.implantSizeId == newValue.id);
      console.log('blade selected : %o', bladeSelected);
      if (bladeSelected) {
        setLateralPartNumber(bladeSelected?.wesLateral);
        setMedialPartNumber(bladeSelected?.wesMedial);
      }
    }
  };

  const handleSelectBladeChange = (event) => {
    setBladeSelectOption(event.target.value);
    clearDropdowns();
  };

  const handleOnChangeLateralWidth = (value) => {
    setLateralPartNumber(`Lateral ${value} mm`);
  };
  const handleOnChangeMedialWidth = (value) => {
    setMedialPartNumber(`Medial ${value} mm`);
  };

  let caseObj = {
    surgeon: '',
    surgeryDate: '',
    extractedManufacturer: '',
    extractedImplant: '',
    extractedSize: '',
    extractedImplantType: '',
    lateralPartNumber: '',
    medialPartNumber: '',
    manufacturer: '',
    implant: '',
    size: '',
    implantType: '',
    submittedBy: 'testUser',
  };
  // const { register, handleSubmit1, watch, formState: { errors } } = useForm();
  const onSubmit = (data) => {
    console.log('onsubmit ' + JSON.stringify(data));
  };
  const navigate = useNavigate();
  const handleSubmit = async () => { };

  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Layout>
      <Box component="form" noValidate autoComplete="off" sx={{ px: { xs: 3, sm: 6 }, py: 4, width: { xs: '100%', sm: 'max-content' }, border: '0px dashed blue' }}>
        <Box display="flex" sx={{ mb: 3, justifyContent: 'start', border: '0px dashed blue' }}>
          <Button variant="text" startIcon={<ArrowBack />} component={Link} to="/Dashboard" sx={{ color: Colors.black, fontSize: '28px', fontWeight: '400', lineHeight: '36px' }} />
          <Typography sx={{ fontSize: '28px', fontWeight: '400', lineHeight: '36px' }}>Size Selector Reference</Typography>
        </Box>

        <Typography sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: '500', mb: 1 }}>Femoral Stem Implant</Typography>
        <Box display="flex" sx={{ mb: 3, flexDirection: { xs: 'column' }, justifyContent: 'flex-start', border: '0px dashed blue' }}>
          <CaseComboBox label="Stem Manufacturer" id="extractedManufacturer" options={manufacturerData} onChange={(e, value) => onSelectManufacturerOption(value)} />
          <CaseComboBox label="Stem Product Name" id="extractedImplant" options={implantData} value={implant} onChange={(e, value) => onSelectImplantOption(value)} />

          {/* <CaseComboBox label="Implant Type" id="extractedImplantType" options={implantTypeData} value={implantType} onChange={(e, value) => setImplantType(value)} /> */}
          <CaseComboBox label="Stem Size" id="extractedSize" options={sizeData} value={size} onChange={(e, value) => onSelectSizeOption(value)} />
        </Box>

        <Typography sx={{ fontSize: '18px', lineHeight: '24px', fontWeight: '500', pt: 3, mb: 1 }}>Suggested  WES Osteotomes (Verify with Sizers per Instructions for Use) </Typography>


        <List sx={{ listStyleType: 'disc', pl: 3 }}>
          {lateralPartNumber != '' && (
            <ListItem sx={{ display: 'list-item' }}>
              <Typography sx={{ fontSize: '14px', lineHeight: '24px', fontWeight: '500' }}> {lateralPartNumber} </Typography>
            </ListItem>
          )}
          {medialPartNumber != '' && (
            <ListItem sx={{ display: 'list-item' }}>
              <Typography sx={{ fontSize: '14px', lineHeight: '24px', fontWeight: '500' }}> {medialPartNumber} </Typography>
            </ListItem>
          )}
        </List>
      </Box>
    </Layout>
  );
}
