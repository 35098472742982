import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';

import { TextField, InputLabel, InputBase } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Layout from './Layout';
import { useMediaQuery } from '@mui/material';
import theme from '../styles/theme';
import { Colors } from '../styles/theme';
import { SearchBox, CaseButton } from '../styles/landingPage';
import educationIcon from '../assets/educationIcon.png';
import OrderStatusIcon from '../assets/OrderStatusIcon.png';
import ReorderIcon from '../assets/ReorderIcon.png';
import SizeSelectorIcon from '../assets/SizeSelectorIcon.png';
import PolygonLanding from '../assets/PolygonLanding.svg';
import AuthService from '../api/authService';
import Navigation from './Navigation';

export default function Dashboard() {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const currentUser = AuthService.getCurrentUser();
  const [showEducationPanel, setShowEducationPanel] = useState(false);
  const [showReorderPanel, setShowReorderPanel] = useState(false);
  const [showSearchForBladePanel, setShowSearchForBladePanel] = useState(false);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setShowEducationPanel(user.visibilityEducationPanel);
      setShowReorderPanel(user.visibilityReorderPanel);
      setShowSearchForBladePanel(user.visibilitySearchForBladePanel);
    }
  }, []);

  return (
    <Layout>
      <Box display="flex" flexDirection="column" sx={{ my: { xs: 1, sm: 4 }, cursor: 'pointer' }}>
        <Box sx={{ textAlign: 'center', my: 2, mx: 4 }}>
          <Typography sx={{ fontSize: '32px', lineHeight: '40px', fontWeight: 700, mb: 2 }}>Hi, {currentUser?.firstName}</Typography>
          <Typography sx={{ fontSize: '16px', lineHeight: '28px', fontWeight: 400 }}>Welcome back to Watson Extraction System </Typography>
        </Box>

        <Box sx={{ position: 'relative' }}>
          <Box component="img" src={PolygonLanding} sx={{ position: 'absolute', width: '100%', bottom: 0 }} />
          <Box display="flex" sx={{ justifyContent: 'space-evenly', my: { xs: 0, sm: 4 }, flexDirection: { xs: 'column', sm: 'row' }, position: 'relative' }}>
            {showSearchForBladePanel && <CaseButton label="Size Selector Reference" CaseIcon={SizeSelectorIcon} nextPage="/SelectBlades"></CaseButton>}
            {showReorderPanel && (
              <>
                {' '}
                <CaseButton label="Reorder" CaseIcon={ReorderIcon} nextPage="/CaseSubmit"></CaseButton>
                <CaseButton label="Order Status" CaseIcon={OrderStatusIcon} nextPage="/ReorderList"></CaseButton>{' '}
              </>
            )}
            {showEducationPanel && <CaseButton label="Product Info" CaseIcon={educationIcon} nextPage="/Education"></CaseButton>}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}
