import { Typography, Button, Box, Container, Grid, Paper } from '@mui/material';
import ExtractionSVG1 from '../assets/ExtractionSVG1.svg';
import { useMediaQuery } from '@mui/material';
import theme from '../styles/theme';
import Layout from './Layout';

function Education() {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Layout>
      <Box>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <Box sx={{ mb: 6 }}>
            <Typography component="span" variant="h3" noWrap sx={{ flexGrow: 1, fontWeight: 500 }}>
              Coming Shortly...
            </Typography>
          </Box>

          <Box component="img" src={ExtractionSVG1} sx={{ width: { xs: '100px', sm: '300px' } }} />
        </Box>
      </Box>
    </Layout>
  );
}

export default Education;
