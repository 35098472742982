import { Typography, Button, Box, Container, Grid, Paper } from '@mui/material';
import Header from '../HeaderSection';
import Banner from './BannerSection';
import BannerMobile from './BannerSectionMobile';
import PreOrderSection from './PreOrderSection';
import PreOrderSectionMobile from './PreOrderSectionMobile';
import KeyFeatures from './KeyFeatures';
import KeyFeaturesMobile from './KeyFeaturesMobile';
import { Osteotomes } from './Osteotomes';
import VideoSection from './VideoSection';
import { Stems, StemsMobile } from './Stems';
import DownloadBrochureSection from './DownloadBrochureSection';
import FooterSection from '../FooterSection';
import { useMediaQuery } from '@mui/material';
import theme from '../../styles/theme';

function Home(props) {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const isDemo = props?.isDemo ?? false;
  return (
    <Container maxWidth="lg" disableGutters sx={{ minHeight: '100vh', backgroundColor: '#FFFFFF', border: '0px dashed grey' }}>
      <Header />
      {matches ? <BannerMobile /> : <Banner />}

      <VideoSection isDemo={isDemo} />
      {matches ? <StemsMobile /> : <Stems />}
      <Osteotomes />
      {matches ? <KeyFeaturesMobile /> : <KeyFeatures />}
      {matches ? <PreOrderSectionMobile /> : <PreOrderSection />}
      <DownloadBrochureSection />
      <FooterSection />
    </Container>
  );
}

export default Home;
