import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, Formik, Field, Form, ErrorMessage, setFieldValue } from 'formik';

import { TextField, Button, Box, Typography, Grid, Stack, FormLabel, FormControl, FormControlLabel, Select, MenuItem, Checkbox } from '@mui/material';
import { Colors } from '../../styles/theme';
import { register } from '../../slices/auth';
import { clearMessage } from '../../slices/message';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Layout from '../Layout';
import { LoginLabel } from '../../styles/login';
import { RegisterTextField, RegisterMultiLineTextField } from '../../styles/registerFormControls';
import axiosInstance from '../../api/axios';
import { facilityValidationSchema } from '../../validations/validationSchema';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AuthService from '../../api/authService';
import { AddedUser } from '../../styles/facility';

const RegisterFacility = () => {
  const [regType, setRegType] = useState(1);
  const [userData, setUserData] = useState([]);

  const [successful, setSuccessful] = useState(false);

  const { message } = useSelector((state) => state.message);

  const location = useLocation();
  const state = location.state;

  const navigate = useNavigate();
  const user = AuthService.getCurrentUser();
  const isModify = state?.hosptialId ? true : false;
  console.log('state %o  isModify %o', state, isModify);
  useEffect(() => {}, []);

  const defaultValues = {
    hospitalName: state?.hospitalName ?? '',
    reorderType: state?.reorderType ?? '',
    reorderRequiresApproval: state?.reorderRequiresApproval ?? '',
    address1: state.address1,
    address2: state.address2,
    city: state.city,
    state: state.state,
    zip5: state.zip5,
    zip4: state.zip4,
    userAuthorizedToApprove: state.userAuthorizedToApprove,
    submittedBy: user?.userName,
  };

  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm({ mode: 'onChange', defaultValues, resolver: yupResolver(facilityValidationSchema) });

  const onSubmit = async (values) => {
    var hospitalObj = values;

    console.log('hospitalObj ' + JSON.stringify(hospitalObj));
    try {
      var resp = await axiosInstance.post('api/Case/AddHospital', hospitalObj);
      console.log(resp.data);
      // navigate('/RegisterUserConfirmed', { state: resp.data });
    } catch (error) {
      console.error(error.message);
    }
  };

  const showUser = watch('reorderRequiresApproval');
  const onError = (errors, e) => console.log(errors);

  return (
    <Layout>
      <Box display="flex" sx={{ mx: 4, my: 1, flexWrap: 'wrap', border: '0px dashed red' }}>
        <Typography sx={{ fontWeight: 700, fontSize: '22px', lineHeight: '28px', flexGrow: 3 }}>Facility Admin Console</Typography>
      </Box>
      <Box display="flex" sx={{ my: 2, border: '0px dashed blue', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', border: '0px dashed yellow', backgroundColor: Colors.white, px: { xs: 4, sm: 30 } }}>
          <Box sx={{ my: 8, display: 'flex', width: { xs: '300px', sm: '350px' }, flexDirection: 'column', alignItems: 'left', border: '0px dashed yellow' }}>
            <Typography align="left" sx={{ fontSize: '30px', lineHeight: '35px', fontWeight: '700' }}>
              Facility Registration
            </Typography>
            <Box component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate sx={{ mt: 4, border: '0px dashed green' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="hospitalName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterTextField
                        id="hospitalName"
                        onChange={onChange}
                        value={value}
                        label="Facility Name"
                        error={!!errors.hospitalName}
                        helperText={errors.hospitalName && `${errors.hospitalName.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ mr: 3, pb: 2, border: '0px dashed red' }}>
                    <Typography sx={{ fontSize: '16px', lineHeight: '19px', fontWeight: '400' }}> Reorder Type</Typography>
                    <Controller
                      control={control}
                      name="reorderType"
                      defaultValue=""
                      render={({ field }) => (
                        <Select {...field} fullWidth>
                          <MenuItem value={0}>Prepayment</MenuItem>
                          <MenuItem value={1}>Invoice on Ship</MenuItem>
                          <MenuItem value={2}>Invoice on Receipt</MenuItem>
                          <MenuItem value={3}>Purchase Order</MenuItem>
                        </Select>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="reorderRequiresApproval"
                    render={({ field: { value, onChange, ...field } }) => (
                      <FormControlLabel control={<Checkbox onChange={onChange} checked={value} {...field} />} label="Reorder Requires Approval" />
                    )}
                  />
                </Grid>
                {showUser && (
                  <Grid item xs={12} md={12}>
                    <Box sx={{ mr: 3, pb: 2, border: '0px dashed red' }}>
                      <Typography sx={{ fontSize: '16px', lineHeight: '19px', fontWeight: '400' }}> User to Approve</Typography>
                      <Controller
                        name="userAuthorizedToApprove"
                        control={control}
                        render={({ field }) => (
                          <Select {...field} fullWidth>
                            {userData.map((item, index) => (
                              <MenuItem key={index} value={item.userId}>
                                {item.firstName + ' ' + item.lastName}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Box>
                  </Grid>
                )}

                <Grid item xs={12} md={12}>
                  <Controller
                    name="address1"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterTextField
                        id="address1"
                        onChange={onChange}
                        value={value}
                        label="Ship To Address1"
                        error={!!errors.address1}
                        helperText={errors.address1 && `${errors.address1.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="address2"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterTextField
                        id="address2"
                        onChange={onChange}
                        value={value}
                        label="Ship To Address2"
                        error={!!errors.address2}
                        helperText={errors.address2 && `${errors.address2.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="city"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterTextField
                        id="city"
                        onChange={onChange}
                        value={value}
                        label="Ship To City"
                        error={!!errors.city}
                        helperText={errors.city && `${errors.city.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterTextField
                        id="state"
                        onChange={onChange}
                        value={value}
                        label="Ship To State"
                        error={!!errors.state}
                        helperText={errors.state && `${errors.state.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="zip5"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterTextField
                        id="zip5"
                        onChange={onChange}
                        value={value}
                        label="Ship To Zip"
                        error={!!errors.zip5}
                        helperText={errors.zip5 && `${errors.zip5.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="notes"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterMultiLineTextField
                        id="notes"
                        rows={3}
                        onChange={onChange}
                        value={value}
                        label="Notes"
                        error={!!errors.notes}
                        helperText={errors.notes && `${errors.notes.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="repInfo"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterMultiLineTextField
                        id="repInfo"
                        rows={3}
                        onChange={onChange}
                        value={value}
                        label="Rep Information"
                        error={!!errors.repInfo}
                        helperText={errors.repInfo && `${errors.repInfo.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="discountFullSet"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterTextField
                        id="discountFullSet"
                        onChange={onChange}
                        value={value}
                        label="Discount - Full Set"
                        error={!!errors.discountFullSet}
                        helperText={errors.discountFullSet && `${errors.discountFullSet.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="discountReplenishment"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterTextField
                        id="discountReplenishment"
                        onChange={onChange}
                        value={value}
                        label="Discount - Replenishment"
                        error={!!errors.discountReplenishment}
                        helperText={errors.discountReplenishment && `${errors.discountReplenishment.message}`}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2, mb: 3, height: '50px', textTransform: 'none' }}>
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default RegisterFacility;
