import Layout from '../Layout';
import { DataGrid, GridToolbar, GridFooter, useGridApiRef, GridActionsCellItem } from '@mui/x-data-grid';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline, Button, Box, Container, Typography, Stack, Checkbox } from '@mui/material';

import { Link, useNavigate } from 'react-router-dom';
import { CustomToolbar, CustomFooter, ClipboardPlusIcon } from '../../styles/caseList';
import { Colors } from '../../styles/theme';
import { LocalSee } from '@mui/icons-material';
import axiosInstance from '../../api/axios';
import { useEffect, useState } from 'react';
import { find } from 'lodash';
import { EditButton, SaveButton } from '../../styles/caseList';
import { Edit, Close, Delete, Save } from '@mui/icons-material';
import { Grid as DevGrid, Table, TableHeaderRow, TableEditRow, TableEditColumn, TableFixedColumns, TableColumnResizing } from '@devexpress/dx-react-grid-material-ui';
import { IntegratedSorting, SortingState, EditingState } from '@devexpress/dx-react-grid';
import { Command } from '../../styles/devGrid';

export default function Manufacturers() {
  const navigate = useNavigate();
  const apiRef = useGridApiRef();

  const showForm = (clickedRow) => {
    //alert(JSON.stringify(clickedRow));
    navigate('/ModifyUser', { state: clickedRow });
  };

  const columnsData = [
    { name: 'manufacturerId', title: 'ID' },
    { name: 'manufacturerName', title: 'Manufacturer Name' },
    { name: 'sortNumber', title: 'SortNumber' },
  ];
  const [tableColumnExtensions] = useState([
    { columnName: 'manufacturerId', width: 100 },
    { columnName: 'manufacturerName', width: 400 },
    { columnName: 'sortNumber', width: 150 },
  ]);

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [columnWidths, setColumnWidths] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (columns.length === 0) {
      setTimeout(() => {
        setColumns(columnsData);
        // let resizeColumns = columnsData.map((c) => {
        //   return {
        //     columnName: c.name,
        //     width: c.name == 'manufacturerId' ? 100 : 'auto',
        //   };
        // });
        setColumnWidths(tableColumnExtensions);
      }, 1000);
    }
  }, [columns]);

  useEffect(() => {
    const getRows = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get('api/Case/GetManufacturers');
        if (response) {
          var manufacturers = response.data.map((x, index) => ({
            ...x,
            id: x.manufacturerId,
            rowId: index,
          }));
          setRows(manufacturers);
          console.log('manufacturers %o', manufacturers);
        } else console.log('no response');
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };
    getRows();
    console.log('useEffect');
  }, []);

  const updateApi = async (row) => {
    try {
      var userObj = row;
      delete userObj.rowId;
      delete userObj.id;
      var resp = await axiosInstance.post('api/Case/UpdateManufacturer', userObj);
      console.log(resp.data);
      // navigate('/users', { state: resp.data });
      return resp.data;
    } catch (error) {
      console.error(error.message);
    }
  };
  const deleteApi = async (id) => {
    try {
      console.log('delete id %o', id);
      //var resp = await axiosInstance.get('api/Case/DeleteManufacturer', id);
      var resp = await axiosInstance.get('api/Case/DeleteManufacturer', { params: { id: id } });
      console.log(resp.data);
      // navigate('/users', { state: resp.data });
      return resp.data;
    } catch (error) {
      console.error(error.message);
    }
  };
  const getRowId = (row) => row.id;
  const changeAddedRows = (value) => {
    console.log('changeAddedRows :%o', value);
    const initialized = value.map((row) => (Object.keys(row).length ? row : { manufacturerId: 0, manufacturerName: '' }));
    setAddedRows(initialized);
  };
  const commitChanges = async ({ added, changed, deleted }) => {
    let changedRows;

    if (added) {
      const startingAddedId = 0;
      console.log('added %o', added);
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
      console.log('added rows %o', changedRows);
    }
    if (changed) {
      let rowId = Object.keys(changed)[0];
      changedRows = rows.map((row) => (changed[row.rowId] ? { ...row, ...changed[rowId] } : row));
      var updated = rows.find((r) => r.rowId == rowId);
      updated = { ...updated, ...changed[rowId] };
      console.log('updated row %o', updated);
      var returnvalue = await updateApi(updated);
      console.log(returnvalue);
    }
    if (deleted) {
      let rowId = Object.keys(deleted)[0];
      var rowToDelete = rows.find((r) => r.rowId == rowId);
      const deletedSet = new Set(deleted);
      changedRows = rows.filter((row) => !deletedSet.has(row.rowId));
      console.log('deleted rows %o', rowToDelete);
      deleteApi(rowToDelete.manufacturerId);
    }
    setRows(changedRows);
  };

  const rowComponent = ({ ...restProps }) => {
    return <Table.Row {...restProps} sx={{}} />;
  };

  return (
    <Layout>
      {loading && <div>Loading</div>}
      {!loading && (
        <>
          <Box display="flex" sx={{ mx: 2, my: 2, flexWrap: 'wrap' }}>
            <Typography sx={{ fontWeight: 700, fontSize: '22px', lineHeight: '28px', flexGrow: 3 }}>Manufacturer Console</Typography>
            <Button variant="contained" component={Link} to="/AddManufacturer" sx={{ mr: 2, fontSize: '14px', lineHeight: '20px', textTransform: 'none' }}>
              Add Manufacturer
            </Button>
          </Box>

          <Box sx={{ mx: 2, backgroundColor: Colors.white }}>
            <DevGrid rows={rows} columns={columns}>
              <SortingState defaultSorting={[{ columnName: 'manufacturerName', direction: 'asc' }]} />
              <EditingState
                editingRowIds={editingRowIds}
                onEditingRowIdsChange={setEditingRowIds}
                rowChanges={rowChanges}
                onRowChangesChange={setRowChanges}
                addedRows={addedRows}
                onAddedRowsChange={changeAddedRows}
                onCommitChanges={commitChanges}
              />
              <IntegratedSorting />
              <Table columnExtensions={tableColumnExtensions} />
              <TableColumnResizing onColumnWidthsChange={setColumnWidths} columnWidths={columnWidths} resizingMode="widget" />
              <TableHeaderRow showSortingControls />
              <TableEditRow rowComponent={rowComponent} />
              <TableEditColumn width={250} showEditCommand showDeleteCommand commandComponent={Command} />
              {/* <TableFixedColumns leftColumns={['manufacturerId']} /> */}
            </DevGrid>
          </Box>
        </>
      )}
    </Layout>
  );
}
