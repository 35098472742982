import * as React from 'react';

import Button from '@mui/material/Button';

import {TextField, InputLabel, InputBase } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Layout from './Layout';
import { useMediaQuery } from "@mui/material";
import theme from '../styles/theme'
import {Colors} from '../styles/theme'
import { useLocation } from 'react-router-dom';

  export default function RecommendedBlades() {
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    const location = useLocation();
    console.log(location.state);
    return (
    <Layout>
        <Box  display="flex" flexDirection="column" sx={{ my:10, cursor:'pointer' }}>
            <Box sx={{textAlign:'center', my:2, mx:4}}>
                <Typography sx={{fontSize:'30px', lineHeight:'36px',fontWeight:700, mb:6}}>Recommended Blades</Typography>

               
                <Typography sx={{fontSize:"22px",lineHeight:"28px", fontWeight:"500", mb:2}}>Lateral Blade: {location.state.lateralPartNumber} </Typography>
             

                <Typography sx={{fontSize:"22px",lineHeight:"28px", fontWeight:"500"}}>Medial Blade: {location.state.medialPartNumber} </Typography>
      
               
                <Button variant="contained" color="primary"  component={Link} to="/Landing"   sx={{ mt:5, textTransform:'none', borderRadius: 100}} >
                    <Typography noWrap> Re Order</Typography> 
                </Button> 

            </Box>
            


        </Box>
    </Layout>
    );
  }

 