import axiosInstance from './axios';
import { useState, useEffect } from 'react';

export const useAxiosPost = (url, payload) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);
  
    useEffect(() => {
      (async () => {
        try {
          const response = await axiosInstance.post(
            url,
            payload
          );
  
          setData(response.data);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoaded(true);
        }
      })();
    }, []);
  
    return { data, error, loaded };
  };
