import { Box, Typography, Button, Divider } from '@mui/material';
import { Colors } from '../styles/theme';
import Polygon5 from '../assets/Polygon5.svg';
import { useMediaQuery } from '@mui/material';
import theme from '../styles/theme';
import moment from 'moment/moment';
import packageInfo from '../../package.json';
import {CopyrightSymbol} from '../styles/symbols'


export default function FooterSection() {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  console.log('version : %o, node env %o', packageInfo.version, process.env.NODE_ENV);
  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          border: '0px dashed yellow',
          zIndex: '1',
          backgroundColor: Colors.primaryBlue1,
          color: Colors.white,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Divider sx={{ width: '100%', background: '#D9D2F4' }} />
        <Typography align="center" variant="body2" sx={{ py: 2 }}>
        {CopyrightSymbol()} Simplex Designs, LLC, {new Date().getFullYear()}. All Rights Reserved. 
        </Typography>
        <Typography align="center"  sx={{ fontSize: '10px', lineHeight: '10px', fontWeight: '400' }}>
          LB-WEB0001 REV 2.0
        </Typography>
      </Box>
    </Box>
  );
}
