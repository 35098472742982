import axios from 'axios';

/**
 * Pre-configured instance of Axios
 */
const axiosInstance = axios.create({
  // Use Local storage to override API base path
  // baseURL:  process.env.REACT_APP_API_URL,
 baseURL: 'https://api.watsonextractionsystem.com/',
  //baseURL: 'https://ec2-44-204-68-213.compute-1.amazonaws.com:800/', //
 //baseURL: 'https://localhost:44366/',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  },
});

export default axiosInstance;
