import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, Formik, Field, Form, ErrorMessage, setFieldValue } from 'formik';

import { TextField, Button, Box, Typography, Grid, Stack, FormLabel, FormControl, FormControlLabel, Select, MenuItem, Checkbox } from '@mui/material';
import { Colors } from '../../styles/theme';
import { register } from '../../slices/auth';
import { clearMessage } from '../../slices/message';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Layout from '../Layout';
import { LoginLabel } from '../../styles/login';
import { RegisterTextField, RegisterMultiLineTextField } from '../../styles/registerFormControls';
import axiosInstance from '../../api/axios';
import { facilityValidationSchema } from '../../validations/validationSchema';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AuthService from '../../api/authService';
import { AddedUser } from '../../styles/facility';



const RegisterFacility = () => {
  var _ = require('lodash');

  const [salesRepId, setSalesRepId] = useState(0);
  const [salesReps, setSalesReps] = useState([]);

  const [successful, setSuccessful] = useState(false);

  const { message } = useSelector((state) => state.message);

  const navigate = useNavigate();
  const user = AuthService.getCurrentUser();
  const location = useLocation();
  const state = location.state;
  const isModify = state?.hospitalId ? true : false;
  console.log('state %o  isModify %o', state, isModify);
  useEffect(() => {
    const url = 'api/Case/GetsalesReps';
    axiosInstance
      .get(url)
      .then(function (response) {
        var sortedData = _.sortBy(response.data, [ 'firstName','lastName']);
            var distributors = sortedData?.map((item) => {
              const obj = {};
              obj.id = item.salesRepId;
              obj.label = item.firstName + '- ' + item.lastName;
              return obj;
            });
        setSalesReps(distributors);
      })
      .catch(function (innerResponse) {
        console.log(innerResponse);
      });
    console.log('useEffect %o', defaultValues);
  }, []);

  const defaultValues = {
    hospitalId: state?.hospitalId ?? 0,
    hospitalName: state?.hospitalName ?? '',
    customerType: state?.customerType ?? '',
    address1: state?.address1 ?? '',
    address2: state?.address2 ?? '',
    city: state?.city ?? '',
    state: state?.state ?? '',
    zip5: state?.zip5 ?? '',
    zip4: state?.zip4 ?? '',
    submittedBy: user?.userName,
    sosCustomerId: state?.sosCustomerId ?? 0,
    sosSalesRepId: state?.sosSalesRepId ?? 0,
  };

  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm({ mode: 'onChange', defaultValues, resolver: yupResolver(facilityValidationSchema) });

  const sendHospitalData = async (values) => {
    var hospitalObj = values;
    console.log('hospitalObj %o', hospitalObj);
    const url = isModify ? 'api/Case/UpdateHospital' : 'api/Case/AddHospital';
    try {
      var resp = await axiosInstance.post(url, hospitalObj);
      console.log(resp.data);
      return resp.data;
    } catch (error) {
      console.error(error.message);
    }
    return null;
  };
  const onSubmit = async (values) => {
    var resp = await sendHospitalData(values);
    if (resp) navigate('/Facilities', { state: resp });
  };

  const onSetAdminUser = async (values) => {
    var resp = await sendHospitalData(values);
    if (resp) navigate('/AdminUsers', { state: resp });
  };

  const showUser = watch('reorderRequiresApproval');
  const onError = (errors, e) => console.log(errors);

  return (
    <Layout>
      <Box display="flex" sx={{ my: 2, border: '0px dashed blue', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', border: '0px dashed yellow', backgroundColor: Colors.white, px: { xs: 4, sm: 30 } }}>
          <Box sx={{ my: 8, display: 'flex', width: { xs: '300px', sm: '350px' }, flexDirection: 'column', alignItems: 'left', border: '0px dashed yellow' }}>
            <Typography align="left" sx={{ fontSize: '30px', lineHeight: '35px', fontWeight: '700' }}>
              Facility Registration
            </Typography>
            <Box component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate sx={{ mt: 4, border: '0px dashed green' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="hospitalName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterTextField
                        id="hospitalName"
                        onChange={onChange}
                        value={value}
                        label="Facility Name"
                        error={!!errors.hospitalName}
                        helperText={errors.hospitalName && `${errors.hospitalName.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ mr: 3, pb: 2, border: '0px dashed red' }}>
                    <Typography sx={{ fontSize: '16px', lineHeight: '19px', fontWeight: '400' }}> Distributor</Typography>
                    <Controller
                      control={control}
                      name="sosSalesRepId"
                      defaultValue={defaultValues.sosSalesRepId}
                      render={({ field: { onChange, value } }) => (
                        <Select  fullWidth value={value}  onChange={(evt) => { onChange(evt.target.value);   }}>
                            {salesReps.map((rep) => (
                              <MenuItem  key={rep.id} value={rep.id}>   {rep.label}  </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </Box>
                </Grid>
               
                <Grid item xs={12} md={12}>
                  <Controller
                    name="address1"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterTextField
                        id="address1"
                        onChange={onChange}
                        value={value}
                        label="Ship To Address1"
                        error={!!errors.address1}
                        helperText={errors.address1 && `${errors.address1.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="address2"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterTextField
                        id="address2"
                        onChange={onChange}
                        value={value}
                        label="Ship To Address2"
                        error={!!errors.address2}
                        helperText={errors.address2 && `${errors.address2.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="city"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterTextField
                        id="city"
                        onChange={onChange}
                        value={value}
                        label="Ship To City"
                        error={!!errors.city}
                        helperText={errors.city && `${errors.city.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterTextField
                        id="state"
                        onChange={onChange}
                        value={value}
                        label="Ship To State"
                        error={!!errors.state}
                        helperText={errors.state && `${errors.state.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="zip5"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterTextField
                        id="zip5"
                        onChange={onChange}
                        value={value}
                        label="Ship To Zip"
                        error={!!errors.zip5}
                        helperText={errors.zip5 && `${errors.zip5.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="notes"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterMultiLineTextField
                        id="notes"
                        rows={3}
                        onChange={onChange}
                        value={value}
                        label="Notes"
                        error={!!errors.notes}
                        helperText={errors.notes && `${errors.notes.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="repInfo"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterMultiLineTextField
                        id="repInfo"
                        rows={3}
                        onChange={onChange}
                        value={value}
                        label="Rep Information"
                        error={!!errors.repInfo}
                        helperText={errors.repInfo && `${errors.repInfo.message}`}
                      />
                    )}
                  />
                  <Grid item xs={12}>
                    <Controller
                      name="discountFullSet"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <RegisterTextField
                          id="discountFullSet"
                          onChange={onChange}
                          value={value}
                          label="Discount - Full Set"
                          error={!!errors.discountFullSet}
                          helperText={errors.discountFullSet && `${errors.discountFullSet.message}`}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="discountReplenishment"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <RegisterTextField
                          id="discountReplenishment"
                          onChange={onChange}
                          value={value}
                          label="Discount - Replenishment"
                          error={!!errors.discountReplenishment}
                          helperText={errors.discountReplenishment && `${errors.discountReplenishment.message}`}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button color="primary" variant="contained" fullWidth onClick={handleSubmit(onSetAdminUser)} sx={{ mt: 2, mb: 3, height: '50px', textTransform: 'none' }}>
                    Add Users
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button color="primary" variant="text" fullWidth type="submit" sx={{ mt: 2, mb: 3, height: '50px', textTransform: 'none' }}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default RegisterFacility;
