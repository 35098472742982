import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Grid, Stack, FormLabel, FormControl, FormControlLabel, Select, MenuItem, Checkbox, Modal } from '@mui/material';
import { Colors } from '../../styles/theme';
import { RegisterTextField, RegisterMultiLineTextField } from '../../styles/registerFormControls';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axiosInstance from '../../api/axios';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '700px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function DistributorSelectionModal(props) {

  const [showConfirm, setShowConfirm] = useState(false);
  const [bladeData, setBladeData] = useState([]);
  const [bladeSelected, setBladeSelected] = React.useState('');
  const [distributorId, setDistributorId] = useState(0);
  const [distributors, setDistributors] = useState([]);

  const [successful, setSuccessful] = useState(false);

  const location = useLocation();
  const state = location.state;

  const navigate = useNavigate();

  useEffect(() => {
    console.log(' useEffect setDistributorIdl:  props %o distributorId %o',props.distributorId, distributorId);
    setDistributorId(props.distributorId);
    setDistributors(props.distributors);
  }, [props.distributorId]);



  const closeModal = () => {
    props.handleClose();
  };
  const onSubmit = async (values) => {
    var obj = values;

    setShowConfirm(true);
    setTimeout(() => closeModal(), 2000);
    //props.handleClose();
  };
  const onError = (errors, e) => console.log(errors);

  const onDistributorChange = (event) => {
    console.log('new distributor: %o',event.target.value);
    setDistributorId(event.target.value);
  };

  

  const saveDistributor = async () => {
    console.log('saveDistributor:  %o', distributorId);
    props.update(distributorId);
    closeModal();
  };

  return (
    <Modal open={props.open} onClose={props.handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography sx={{ fontWeight: 500, fontSize: '22px', lineHeight: '28px' }}>Select Distributor</Typography>
        </Box>
        <Box sx={{ mb:1}}>
          <Typography sx={{  }}>Sales Rep: {props.repName} </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Box sx={{ mr: 3, pb: 2, border: '0px dashed red' }}>
              <Typography sx={{ fontSize: '16px', lineHeight: '19px', fontWeight: '400' }}> Distributors</Typography>
              <Select value={distributorId} onChange={onDistributorChange} fullWidth>
                {distributors?.map((item, index) => (
                  <MenuItem key={index} value={item.sosCustomerId}>
                    {item.hospitalName }
                  </MenuItem>
                ))}
              </Select>
            </Box>
          
          </Grid>

         
        </Grid>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button color="primary" variant="contained" onClick={saveDistributor} sx={{ mt: 2, mb: 3, height: '50px', textTransform: 'none', borderRadius: 100 }}>
            Update Distributor
          </Button>
          <Button color="primary" variant="text" onClick={() => props.handleClose()} sx={{ mx: 2, mt: 2, mb: 3, height: '50px', textTransform: 'none' }}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
