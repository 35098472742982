import { useState, useEffect } from 'react';
import { TextField, Button, Checkbox, Box, FormControlLabel, Typography, Stack, Fab, Alert } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Layout from './Layout';
import { useMediaQuery } from '@mui/material';
import theme from '../styles/theme';
import { Colors } from '../styles/theme';
import { ArrowBack, AddCircle, RemoveCircle } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { CaseTextField, CaseComboBox, CaseDatePickerDesktop } from '../styles/caseFormControls';
import { RegisterTextField, RegisterMultiLineTextField } from '../styles/registerFormControls';
import { useAxiosPost } from '../api/api';
import { manufacturers, implants } from '../api/data';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { reorderValidationSchema } from '../validations/validationSchema';
import axiosInstance from '../api/axios';
import { LocalizationProvider, DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useDebugValue } from 'react';
import { cloneDeep } from 'lodash';
import { AddButton, RemoveButton } from '../styles/caseList';
import AuthService from '../api/authService';

export default function CaseSubmit() {
  const user = AuthService.getCurrentUser();

  var _ = require('lodash');
  const [surgeryDate, setSurgeryDate] = useState(dayjs());
  const [surgeon, setSurgeon] = useState('');
  const [medicalRecord1, setMedicalRecord1] = useState('');
  const [medicalRecord2, setMedicalRecord2] = useState('');

  const [hospitalSelected, sethospitalSelected] = useState('');

  const [bladeData, setBladeData] = useState([]);
  const [hospitalData, setHospitalData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [bladeSelected, setBladeSelected] = useState('');
  const [bladesUsed, setBladesUsed] = useState([]);

  const [salesRep, setSalesRep] = useState('');
  const [salesReps, setSalesReps] = useState('');

  const [increment, setIncrement] = useState(1);

  const [facilityErrorMsg, setFacilityErrorMsg] = useState('');
  const [distributorErrorMsg, setDistributorErrorMsg] = useState('');
  const [instrErrorMsg, setInstrErrorMsg] = useState('');

  const bladeHeaders = [
    { field: 'id', headerName: 'Id', width: 50, editable: false, hide: true },
    { field: 'sosSku', headerName: 'SKU', width: 80, editable: false, hide: true },
    {
      field: 'bladeDesc',
      headerName: 'Item Description',
      width: 300,
      editable: false,
      valueGetter: (params) => {
        return `${params.row.sosSku || ''}  ${params.row.bladeDesc || ''}`;
      },
    },
    {
      field: 'count',
      type: 'actions',
      headerName: 'Count',
      type: 'number',
      width: 60,
      editable: false,
    },

    {
      field: 'action',
      type: 'actions',

      getActions: (params) => [
        <GridActionsCellItem icon={<RemoveCircle />} label="Toggle Admin" onClick={() => decrementCount(params.row.sosItemId)} />,
        <GridActionsCellItem icon={<AddCircle />} label="Delete" onClick={() => incrementCount(params.row.sosItemId)} />,
      ],
    },
    { field: 'sosItemId', headerName: 'ItemId', width: 200, editable: false, hide: true },
  ];

  const location = useLocation();
  const reorderObj = location.state;

  let caseObj = reorderObj ?? {
    surgeon: '',
    surgeryDate: '',
    medicalRecord1: '',
    medicalRecord2: '',
    medicalRecord3: '',
    hospitalId: '',
    submittedBy: '',
    caseDetails: [],
    isTrial: false,
    notes: '',
    salesRep: '',
  };

  const getSurgeons = async (hospitalId) => {
    const url = 'api/Case/GetSurgeonsByHospitalId';

    var response = await axiosInstance.get(url, { params: { id: hospitalId } });

    if (response && response.data) {
      var sortedData = _.sortBy(response.data, ['lastName', 'firstName']);
      var users = sortedData?.map((item) => {
        const obj = {};
        obj.id = item.userId;
        obj.label = item.lastName + ', ' + item.firstName;
        obj.userTypeId = item.userTypeId;
        return obj;
      });
      setUsersData(users);
      //setUsersData(users.filter((u) => u.usetTypeId == 4));
    } else setUsersData([]);

    setSurgeon(caseObj.surgeon);
  };

  const getAllUsers = async () => {
    try {
      // const  response = await axiosInstance.get('http://ec2-44-203-70-77.compute-1.amazonaws.com:800/api/Case');
      const response = await axiosInstance.get('api/Case/GetUsers');
      if (response) {
        var users = response.data.map((item) => {
          const obj = {};
          obj.id = item.userId;
          obj.label = item.firstName + ' ' + item.lastName;
          obj.userTypeId = item.userTypeId;
          return obj;
        });
        setUsersData(users);
        //setUsersData(users.filter((u) => u.usetTypeId == 4));
      } else console.log('no response');
      console.log(
        'users %o surgeons %o',
        users,
        users.filter((u) => u.userTypeId == 4)
      );
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    const url = 'api/Case/GetBladeHospitalsByUserId';

    axiosInstance
      .get(url, { params: { userId: user.userId } })
      .then(function (innerResponse) {
        if (innerResponse.data.blades) {
          var sortedBlades = _.sortBy(innerResponse.data.blades, ['sosSku']);
          var blades = sortedBlades?.map((item) => {
            const obj = {};
            obj.id = item.bladeId;
            obj.bladeId = item.bladeId;
            obj.label = item.sosSku + '  ' + item.bladeDesc;
            obj.sosSku = item.sosSku;
            obj.bladeDesc = item.bladeDesc;
            obj.sosItemId = item.sosItemId;

            return obj;
          });
          setBladeData(blades);
          console.log(bladeData);
        }

        var hospitals = innerResponse.data.hospitals.map((item) => {
          const obj = {};
          obj.id = item.sosCustomerId;
          obj.label = item.hospitalName;
          obj.salesRepId= item.sosSalesRepId;
          return obj;
        });
        setHospitalData(hospitals);

       axiosInstance.get('api/Case/GetsalesReps')
          .then(function (response) {
            var sortedData = _.sortBy(response.data, [ 'firstName','lastName']);
            var distributors = sortedData?.map((item) => {
              const obj = {};
              obj.id = item.salesRepId;
              obj.label = item.firstName + '- ' + item.lastName;
              return obj;
            });
            setSalesReps(distributors);  
            if(reorderObj!=null)
              setSalesRep(distributors.find(s=> s.id==reorderObj.sosSalesRepId));
            })
          .catch(function (innerResponse) { console.log(innerResponse);     });

        if (reorderObj != null) {
          caseObj = reorderObj;
          console.log('caseObj %o', caseObj);

          var hospital = hospitals.find((h) => h.id == caseObj.hospitalId);
          console.log('hospital %o', hospital);
          sethospitalSelected(hospital);
          
          getSurgeons(hospital.id);
          setSurgeon(caseObj.surgeon);
          setMedicalRecord1(caseObj.medicalRecord1);
          setMedicalRecord2(caseObj.medicalRecord2);
          var bladesAdded = caseObj.caseDetails?.map((item, index) => {
            item.id = index;
            return item;
          });
          setBladesUsed(bladesAdded);
          // setSurgeryDate(caseObj.surgeryDate);
        }
      })
      .catch(function (innerResponse) {
        console.log(innerResponse);
      });
  }, []);

  const defaultValues = {};
  const [openOrderModal, setOpenOrderModal] = useState(false);

  defaultValues.hospitalName = '';
  defaultValues.address1 = '';
  defaultValues.address2 = '';
  defaultValues.city = '';
  defaultValues.state = '';
  defaultValues.zip5 = '';
  defaultValues.reorderRequiresApproval = '';
  defaultValues.shipDate = '';
  defaultValues.receivedDate = '';
  defaultValues.shipStatus = '';
  defaultValues.billed = '';

  // const { register, handleSubmit1, watch, formState: { errors } } = useForm();
  const handleAddBlade = () => {
    var blade = bladesUsed.find((i) => i.sosItemId == bladeSelected.sosItemId);
    setInstrErrorMsg('');
    if (blade != null) {
      setBladesUsed((prevBlades) => {
        return prevBlades.map((row) => (row.sosItemId == blade.sosItemId ? { ...row, count: row.count + 1 } : row));
      });
    } else {
      setIncrement((increment) => increment + 1);
      bladeSelected.id = increment;
      bladeSelected.count = 1;
      setBladesUsed((prevBlades) => [...prevBlades, cloneDeep(bladeSelected)]);
      console.log(bladesUsed);
    }
  };

  const incrementCount = (itemId) => {
    console.log('item %o', itemId);
    var blade = bladesUsed.find((i) => i.sosItemId == itemId);
    if (blade != null) {
      setBladesUsed((prevBlades) => {
        return prevBlades.map((row) => (row.sosItemId == blade.sosItemId ? { ...row, count: row.count + 1 } : row));
      });
    }
  };
  const decrementCount = (itemId) => {
    console.log('item %o', itemId);
    var blade = bladesUsed.find((i) => i.sosItemId == itemId);
    if (blade != null) {
      setBladesUsed((prevBlades) => {
        return prevBlades.map((row) => (row.sosItemId == blade.sosItemId ? { ...row, count: Math.max(row.count - 1, 0) } : row));
      });
    }
  };
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (hospitalSelected == '' || bladesUsed.length == 0 || (!salesRep || salesRep == '') ) {
      if (hospitalSelected == '') setFacilityErrorMsg('Please select facility');
      if (!salesRep || salesRep == '') setDistributorErrorMsg('Please select Distributor');
      if (bladesUsed.length == 0) setInstrErrorMsg('Please add at least one instrument');
      return;
    }
    caseObj.surgeon = surgeon;
    caseObj.surgeryDate = surgeryDate.toDate();
    caseObj.hospitalId = hospitalSelected.id;
    caseObj.hospitalName = hospitalSelected.label;
    caseObj.sosSalesRepId= salesRep.id;
    caseObj.medicalRecord1 = medicalRecord1;
    caseObj.medicalRecord2 = medicalRecord2;

    if (bladesUsed.length > 0) {
      caseObj.caseDetails = bladesUsed
        .filter((i) => i.count > 0)
        .map((item) => {
          const obj = {};
          // obj.id = item.id;
          obj.bladeId = item.bladeId;
          obj.bladeDesc = item.bladeDesc;
          obj.sosSku = item.sosSku;
          obj.sosItemId = item.sosItemId;
          obj.count = item.count;
          return obj;
        });
    }

    console.log('caseObj ' + JSON.stringify(caseObj));
    try {
      navigate('/CaseConfirm', { state: caseObj });
    } catch (error) {
      console.error(error.message);
    }
  };

  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    control,
    handleSubmit1,
    watch,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onChange', defaultValues, resolver: yupResolver(reorderValidationSchema) });

  return (
    <Layout>
      <Box component="form" noValidate autoComplete="off" sx={{ px: { xs: 3, sm: 6 }, py: 4, border: '0px dashed blue' }}>
        <Box display="flex" sx={{ mb: 3, justifyContent: 'start', border: '0px dashed blue' }}>
          <Button
            variant="text"
            startIcon={<ArrowBack />}
            component={Link}
            to="/ReorderList"
            sx={{ color: Colors.black, fontSize: '28px', fontWeight: '400', lineHeight: '36px' }}
          />
          <Typography sx={{ fontSize: '28px', fontWeight: '400', lineHeight: '36px' }}>Reorder</Typography>
        </Box>
        <Box display="flex" sx={{ mb: 3, maxWidth: '600px', flexDirection: { xs: 'column', sm: 'column' }, justifyContent: 'flex-start', border: '0px dashed blue' }}>
          <CaseComboBox
            label="Facility"
            id="hospitalSelected"
            value={hospitalSelected}
            options={hospitalData}
            onChange={(e, value) => {
              sethospitalSelected(value);
              setFacilityErrorMsg('');
              caseObj.surgeon = '';
              getSurgeons(value.id);
              let distributor = salesReps.find(i=>i.id==value.salesRepId);
              console.log(' hostpital %o, distributor %o salesReps %o', value, distributor, salesReps);
              setSalesRep(distributor ??'' );
            }}
          />

          <CaseComboBox label="Distributor" id="salesRep" value={salesRep}   options={salesReps} onChange={(e, value) =>{
             setSalesRep(value);
             setDistributorErrorMsg('');
              } } />
          <CaseComboBox label="Surgeon" id="surgeonSelected" value={surgeon} optional="true" options={usersData} onChange={(e, value) => setSurgeon(value?.label)} />

          <Box sx={{ mr: 3, pb: 2, width: '100%' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Typography sx={{ fontSize: '11px', lineHeight: '16px', fontWeight: '500' }}> Surgery Date </Typography>
              <DesktopDatePicker
                minDate={dayjs('2017-01-01')}
                value={surgeryDate}
                onChange={(newValue) => {
                  console.log('%o', newValue);
                  setSurgeryDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                InputProps={{ sx: { backgroundColor: Colors.white, width: '100%' } }}
              />
            </LocalizationProvider>
          </Box>

          {/* <CaseDatePickerDesktop  label="Surgery Date" onChange={(newValue)=>setSurgeryDate(newValue)}/> */}

          {/* <Typography sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: '500', mb: 1 }}>Medical Record Number</Typography>
          <Box display="flex" sx={{ mb: 3, flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'flex-start', border: '0px dashed blue' }}>
            <CaseTextField label="Med Record Num 1" optional="true" value={medicalRecord1} onChange={(e) => setMedicalRecord1(e.target.value)} />
            <CaseTextField label="Med Record Num 2" optional="true" value={medicalRecord2} onChange={(e) => setMedicalRecord2(e.target.value)} />
          </Box> */}
          <Typography sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: '500', mb: 1 }}>Items To Reorder</Typography>
          <Box display="flex" sx={{ flexDirection: { xs: 'column', sm: 'row' }, maxWidth: '600px', justifyContent: 'flex-start', border: '0px dashed blue' }}>
            <CaseComboBox label="Item Selection" options={bladeData} onChange={(e, value) => setBladeSelected(value)} />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddBlade}
              sx={{ ml: { xs: 0, sm: 2 }, my: { xs: 2, sm: 3 }, py: 1, textTransform: 'none', borderRadius: 100, width: { sm: '200px' } }}
            >
              <Typography noWrap>Add To Order</Typography>
            </Button>
          </Box>

          {bladesUsed.length > 0 && (
            <Box sx={{ height: 371, mb: 4 }}>
              <Typography sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: '500', mb: 1 }}>Selected Items</Typography>
              <DataGrid
                rows={bladesUsed}
                columns={bladeHeaders}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                sx={{ backgroundColor: Colors.white, '& MuiDataGrid-footerContainer': { backGroundColor: Colors.black } }}
              />
            </Box>
          )}
          {facilityErrorMsg != '' && (
            <Alert icon={false} severity="error">
              {' '}
              {facilityErrorMsg}{' '}
            </Alert>
          )}
          
          {distributorErrorMsg != '' && (
            <Alert icon={false} severity="error">
              {' '}
              {distributorErrorMsg}{' '}
            </Alert>
          )}
          {instrErrorMsg != '' && (
            <Alert icon={false} severity="error">
              {' '}
              {instrErrorMsg}{' '}
            </Alert>
          )}
          <Box display="flex" sx={{ my: 3, flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'flex-start', border: '0px dashed blue' }}>
            <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ textTransform: 'none', borderRadius: 100, px: 4, py: 1 }}>
              <Typography noWrap> Next</Typography>
            </Button>
            <Button
              variant="text"
              color="primary"
              component={Link}
              to="/CaseList"
              sx={{ ml: { xs: 0, sm: 2 }, mt: { xs: 2, sm: 0 }, px: 2, py: 1, textTransform: 'none', borderRadius: 100 }}
            >
              <Typography noWrap> Cancel</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}
