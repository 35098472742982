import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Grid, Stack, FormLabel, FormControl, FormControlLabel, Select, MenuItem, Checkbox, Modal } from '@mui/material';
import { Colors } from '../styles/theme';
import { RegisterTextField, RegisterMultiLineTextField } from '../styles/registerFormControls';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axiosInstance from '../api/axios';
import ReactGA from 'react-ga';
import useAnalytics from '../shared/useAnalytics';
import { AddedBlade } from '../styles/facility';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '700px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BladeSelectionModal(props) {
  const gaEvent = useAnalytics('OrderModal');
  const [showConfirm, setShowConfirm] = useState(false);
  const [bladeData, setBladeData] = useState([]);
  const [bladeSelected, setBladeSelected] = React.useState('');
  const [bladesAdded, setBladesAdded] = useState([]);
  const [successful, setSuccessful] = useState(false);

  const location = useLocation();
  const state = location.state;

  const navigate = useNavigate();

  useEffect(() => {
    const url = 'api/Case/GetBladeDataForDropdowns';

    setBladesAdded(props.blades);

    axiosInstance
      .get(url)
      .then(function (innerResponse) {
        setBladeData(innerResponse.data.blades);
        console.log(innerResponse.data.blades);
      })
      .catch(function (innerResponse) {});
  }, []);

  const closeModal = () => {
    props.handleClose();
  };
  const onSubmit = async (values) => {
    var obj = values;

    setShowConfirm(true);
    setTimeout(() => closeModal(), 2000);
    //props.handleClose();
  };
  const onError = (errors, e) => console.log(errors);

  const onBladeChange = (event) => {
    setBladeSelected(event.target.value);
  };

  const addBlade = () => {
    if (!bladesAdded.find((i) => i.bladeId == bladeSelected)) {
      var blade = bladeData.find((u) => u.bladeId == bladeSelected);
      console.log(blade);
      var bladeObj = blade;
      bladeObj.count = 1;
      console.log(bladeObj);
      setBladesAdded((prev) => [...prev, bladeObj]);
      console.log('bladesAdded: %o', bladesAdded);
    }
  };

  const removeBlade = (bladeId) => {
    setBladesAdded(bladesAdded.filter((item) => item.bladeId != bladeId));
  };

  const incrementCount = (blade) => {
    // var blade = bladesAdded.find((i) => i.sosItemId == sosItemId);
    if (blade != null) {
      setBladesAdded((prevBlades) => {
        return prevBlades.map((row) => (row.sosItemId == blade.sosItemId ? { ...row, count: row.count + 1 } : row));
      });
    }
  };
  const decrementCount = (blade) => {
    if (blade != null) {
      setBladesAdded((prevBlades) => {
        return prevBlades.map((row) => (row.sosItemId == blade.sosItemId ? { ...row, count: Math.max(row.count - 1, 0) } : row));
      });
    }
  };

  const saveBlades = async () => {
    console.log('saveBlades:  %o', bladesAdded);
    props.update(bladesAdded.filter((b) => b.count > 0));
    closeModal();
  };

  return (
    <Modal open={props.open} onClose={props.handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography sx={{ fontWeight: 500, fontSize: '22px', lineHeight: '28px' }}>Update Blades</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Box sx={{ mr: 3, pb: 2, border: '0px dashed red' }}>
              <Typography sx={{ fontSize: '16px', lineHeight: '19px', fontWeight: '400' }}> Blades</Typography>
              <Select value={bladeSelected} onChange={onBladeChange} fullWidth>
                {bladeData.map((item, index) => (
                  <MenuItem key={index} value={item.bladeId}>
                    {item.bladeDesc + ' (' + item.sosSku + ')'}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Button color="primary" variant="text" onClick={addBlade} sx={{ height: '50px', textTransform: 'none' }}>
              + Add Instrument
            </Button>
          </Grid>

          <Grid item xs={12} md={12}>
            {bladesAdded?.map((item, index) => {
              return <AddedBlade key={index} id={index} blade={item} increment={() => incrementCount(item)} decrement={() => decrementCount(item)} />;
            })}
          </Grid>
        </Grid>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button color="primary" variant="contained" onClick={saveBlades} sx={{ mt: 2, mb: 3, height: '50px', textTransform: 'none', borderRadius: 100 }}>
            Update Blades
          </Button>
          <Button color="primary" variant="text" onClick={() => props.handleClose()} sx={{ mx: 2, mt: 2, mb: 3, height: '50px', textTransform: 'none' }}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
