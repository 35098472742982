import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, Formik, Field, Form, ErrorMessage, setFieldValue } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Box, Typography, Grid, Checkbox, Stack, FormLabel, FormControl, FormControlLabel, Select, MenuItem } from '@mui/material';
import { Colors } from '../../styles/theme';
import { register } from '../../slices/auth';
import { clearMessage } from '../../slices/message';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Layout from '../Layout';
import { LoginLabel } from '../../styles/login';
import { RegisterTextField } from '../../styles/registerFormControls';
import axiosInstance from '../../api/axios';
import finalPropsSelectorFactory from 'react-redux/es/connect/selectorFactory';

const ModifyUser = () => {
  const location = useLocation();
  const state = location.state;
  //
  const initialValues = {
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    userTypeId: 1,
    firstname: '',
    lastname: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip5: '',
    isApprovedForLogin: 'false',
  };

  initialValues.firstname = state.firstName;
  initialValues.email = state.email;
  initialValues.lastname = state.lastName;
  initialValues.username = state.username;
  initialValues.address1 = state.address1;
  initialValues.address2 = state.address2;
  initialValues.phone = state.phone;
  initialValues.city = state.city;
  initialValues.state = state.state;
  initialValues.zip5 = state.zip5;
  initialValues.isApprovedForLogin = state.isApprovedForLogin;
  console.log(initialValues);
  const [regType, setRegType] = useState(state.userTypeId);
  const [activated, setActivated] = useState(state.isApprovedForLogin);
  const [successful, setSuccessful] = useState(false);

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {}, []);

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .test('len', 'The username must be between 3 and 20 characters.', (val) => val && val.toString().length >= 3 && val.toString().length <= 20)
      .required('This field is required!'),
    email: Yup.string().email('This is not a valid email.').required('This field is required!'),
    password: Yup.string()
      .test('len', 'The password must be between 6 and 40 characters.', (val) => val && val.toString().length >= 6 && val.toString().length <= 40)
      .required('This field is required!'),
  });

  const handleRegister = (formValue) => {
    const { username, email, password } = formValue;

    setSuccessful(false);

    dispatch(register({ username, email, password }))
      .unwrap()
      .then(() => {
        setSuccessful(true);
      })
      .catch(() => {
        setSuccessful(false);
      });
  };

  const onSubmit1 = (values) => {
    console.log(values);
    alert(JSON.stringify(values, null, 2));
  };

  const handleSubmit = async (values) => {
    var userObj = location.state;
    userObj.userTypeId = regType;
    userObj.isApprovedForLogin = activated;
    userObj.firstName = values.firstname;
    userObj.lastName = values.lastname;
    userObj.email = values.email;
    userObj.phone = values.phone;
    userObj.address1 = values.address1;
    userObj.address2 = values.address2;
    userObj.city = values.city;
    userObj.state = values.state;
    userObj.zip5 = values.zip5;

    // alert(JSON.stringify(userObj, null, 2));
    console.log('userObj ' + JSON.stringify(userObj));
    try {
      //var resp= await axiosInstance.post('http://ec2-44-203-70-77.compute-1.amazonaws.com:800/api/Case', caseObj );

      var resp = await axiosInstance.post('api/Case/UpdateUser', userObj);
      console.log(resp.data);
      navigate('/users', { state: resp.data });
    } catch (error) {
      console.error(error.message);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    //   validationSchema: validationSchema,
    //enableReinitialize: false,
    onSubmit: handleSubmit,
  });

  return (
    <Layout>
      <Box display="flex" sx={{ my: 10, border: '0px dashed blue', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', border: '0px dashed yellow', backgroundColor: Colors.white, px: { xs: 4, sm: 30 } }}>
          <Box sx={{ my: 8, display: 'flex', width: { xs: '300px', sm: '350px' }, flexDirection: 'column', alignItems: 'left', border: '0px dashed yellow' }}>
            <Typography align="left" sx={{ fontSize: '30px', lineHeight: '35px', fontWeight: '700' }}>
              {' '}
              Update User{' '}
            </Typography>
            <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 4, border: '0px dashed green' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstname"
                    label="FirstName"
                    name="firstname"
                    value={formik.values.firstname}
                    autoFocus
                    sx={{ 'label + &': { mt: 2 } }}
                    onChange={formik.handleChange}
                    error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                    helperText={formik.touched.firstname && formik.errors.firstname}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastname"
                    label="Lastname"
                    name="lastname"
                    value={formik.values.lastname}
                    autoFocus
                    sx={{ 'label + &': { mt: 2 } }}
                    onChange={formik.handleChange}
                    error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                    helperText={formik.touched.lastname && formik.errors.lastname}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    value={formik.values.email}
                    autoFocus
                    sx={{ 'label + &': { mt: 2 } }}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    label="Phone"
                    name="phone"
                    value={formik.values.phone}
                    autoFocus
                    sx={{ 'label + &': { mt: 2 } }}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ mr: 3, pb: 2, border: '0px dashed red' }}>
                    <Typography sx={{ fontSize: '16px', lineHeight: '19px', fontWeight: '400' }}> Registration Type</Typography>

                    <Select name="regType" value={regType} onChange={(e) => setRegType(e.target.value)} sx={{ width: '100%' }}>
                      <MenuItem value={1}>Sales Representative</MenuItem>
                      <MenuItem value={3}>Facility Admin</MenuItem>
                      <MenuItem value={4}>Surgeon</MenuItem>
                      <MenuItem value={2}>Admin</MenuItem>
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel control={<Checkbox checked={activated} onChange={(e) => setActivated(e.target.checked)} />} label="Activated" />
                </Grid>
                <Grid item xs={12}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    id="address1"
                    label="Address"
                    name="address1"
                    value={formik.values.address1}
                    autoFocus
                    sx={{ 'label + &': { mt: 2 } }}
                    onChange={formik.handleChange}
                    error={formik.touched.address1 && Boolean(formik.errors.address1)}
                    helperText={formik.touched.address1 && formik.errors.address1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    id="city"
                    label="City"
                    name="city"
                    value={formik.values.city}
                    autoFocus
                    sx={{ 'label + &': { mt: 2 } }}
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    id="state"
                    label="State"
                    name="state"
                    value={formik.values.state}
                    autoFocus
                    sx={{ 'label + &': { mt: 2 } }}
                    onChange={formik.handleChange}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RegisterTextField
                    margin="normal"
                    required
                    fullWidth
                    id="zip5"
                    label="Zip Code"
                    name="zip5"
                    value={formik.values.zip5}
                    autoFocus
                    sx={{ 'label + &': { mt: 2 } }}
                    onChange={formik.handleChange}
                    error={formik.touched.zip5 && Boolean(formik.errors.zip5)}
                    helperText={formik.touched.zip5 && formik.errors.zip5}
                  />
                </Grid>
              </Grid>

              <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2, mb: 3, height: '50px', textTransform: 'none' }}>
                {' '}
                Update{' '}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default ModifyUser;
