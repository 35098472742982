import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, Formik, Field, Form, ErrorMessage, setFieldValue } from 'formik';

import { TextField, Button, Box, Typography, Grid, Stack, FormLabel, FormControl, FormControlLabel, Select, MenuItem, Checkbox } from '@mui/material';
import { Colors } from '../../styles/theme';
import { register } from '../../slices/auth';
import { clearMessage } from '../../slices/message';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Layout from '../Layout';
import { LoginLabel } from '../../styles/login';
import { RegisterTextField, RegisterMultiLineTextField } from '../../styles/registerFormControls';
import axiosInstance from '../../api/axios';
import { manufacturerValidationSchema } from '../../validations/validationSchema';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AuthService from '../../api/authService';
import { AddedUser } from '../../styles/facility';

export default function AddManufacturer() {
  const [regType, setRegType] = useState(1);
  const [userData, setUserData] = useState([]);

  const [successful, setSuccessful] = useState(false);

  const { message } = useSelector((state) => state.message);

  const navigate = useNavigate();
  const user = AuthService.getCurrentUser();
  const location = useLocation();
  const state = location.state;

  useEffect(() => {
    console.log('useEffect %o', defaultValues);
  }, []);

  const defaultValues = {
    manufacturerId: state?.manufacturerId ?? 0,
    manufacturerName: state?.manufacturerName ?? '',
    sortNumber: state?.sortNumber ?? 10,
  };

  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm({ mode: 'onChange', defaultValues, resolver: yupResolver(manufacturerValidationSchema) });

  const sendData = async (values) => {
    var obj = values;
    const url = 'api/Case/AddManufacturer';
    try {
      var resp = await axiosInstance.post(url, obj);
      console.log(resp.data);
      return resp.data;
    } catch (error) {
      console.error(error.message);
    }
    return null;
  };
  const onSubmit = async (values) => {
    console.log('onSubmit %o', values);
    var resp = await sendData(values);
    if (resp) navigate('/Manufacturers', { state: resp });
  };

  const onError = (errors, e) => console.log(errors);

  return (
    <Layout>
      <Box display="flex" sx={{ my: 2, border: '0px dashed blue', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', border: '0px dashed yellow', backgroundColor: Colors.white, px: { xs: 4, sm: 30 } }}>
          <Box sx={{ my: 8, display: 'flex', width: { xs: '300px', sm: '350px' }, flexDirection: 'column', alignItems: 'left', border: '0px dashed yellow' }}>
            <Typography align="left" sx={{ fontSize: '30px', lineHeight: '35px', fontWeight: '700' }}>
              Add Manufacturer
            </Typography>
            <Box component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate sx={{ mt: 4, border: '0px dashed green' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="manufacturerName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterTextField
                        id="manufacturerName"
                        onChange={onChange}
                        value={value}
                        label="Manufacturer Name"
                        error={!!errors.manufacturerName}
                        helperText={errors.manufacturerName && `${errors.manufacturerName.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="sortNumber"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RegisterTextField
                        id="sortNumber"
                        onChange={onChange}
                        value={value}
                        label="Sort Number"
                        error={!!errors.sortNumber}
                        helperText={errors.sortNumber && `${errors.sortNumber.message}`}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2, mb: 3, height: '50px', textTransform: 'none' }}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}
